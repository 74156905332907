import React, { useContext } from 'react';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import GlobalContext from './GlobalContext';

function ChartTypeSettings(props) {
  const { chartType, setChartType } = useContext(GlobalContext);

  const chartTypeSettings = [
    { name: 'TTK', value: 'ttk', description: 'Time to Kill' },
    { name: 'STK', value: 'stk', description: 'Shots to Kill' },
    { name: 'DPS', value: 'dps', description: 'Damage per Second' },
  ];

  return (
    <ButtonGroup toggle className="setting-buttons">
      {chartTypeSettings.map((chartTypeSettings, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="outline-info"
          name="radio"
          value={chartTypeSettings.value}
          checked={chartType === chartTypeSettings.value}
          onChange={(e) => setChartType(e.currentTarget.value)}
        >
          {chartTypeSettings.name}
        </ToggleButton>
      ))}
    </ButtonGroup>

  );
}

export default ChartTypeSettings;