import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner, Dropdown } from 'react-bootstrap';

import GlobalContext from './GlobalContext';
import { getFinalStatsForBp } from './dataUtils';

// Components
import DamageChartArea from './DamageChartArea';
import GunList from './GunList';
import NavBar from './NavBar';
import ToDoList from './ToDoList';
import StatsTable from './StatsTable';
import Settings from './Settings';

import { convertEntireUrlStrToBpListWithBpName } from './urlToNumber';
import ShareUrlBar from './ShareUrlBar';


import { useParams } from "react-router-dom";

function generateAllStatsWithAttachments(bpList) {
  const result = [];
  bpList.filter(x => x.deleted === false).forEach(bp => result.push(getFinalStatsForBp(bp)));
  return result;
}

function Share() {
  let { shareId } = useParams();
  // initialize Share with data from localstorage if available. 
  function useStickyState(defaultValue, key) {
    const [value, setValue] = useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });
    useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  };

  const [visitCount, setVisitCount] = useStickyState(0, 'visitCount');

  const [justOpened, setJustOpened] = useState(true);

  useEffect(() => {
    if (justOpened) {
      setVisitCount(visitCount + 1);
    }

    setJustOpened(false);
  }, [justOpened, setVisitCount, visitCount]);

  // bpList is the list of blueprints.
  // each blueprint is an array of up to 6 elements.
  // 0th element is the gun and its base stats.
  // 1st-5th elements are attachments and their property modifiers. 
  const [bpList, setBpList] = useStickyState([], 'blueprintList');

  function updateBpList(bp, key) {
    // updates the bpList either by: 
    // 1. adding a new blueprint to the list, or
    // 2. removing an item from a particular keyition.
    let newBpList = JSON.parse(JSON.stringify(bpList));

    // adding to the front of the list
    if (bp !== undefined) {
      bp = JSON.parse(JSON.stringify(bp));
      const dupeCount = newBpList.filter(x => x.gun === bp.gun).length;
      bp.bpName = bp.gun + ' ' + (dupeCount + 1);
      bp.deleted = false;
      bp.dateAdded = Date.now();
      newBpList.unshift(bp);
    } else {
      // remove entire list
      if (key === 'all') newBpList = [];
      // remove 1 item
      else {
        const indexToDel = newBpList.findIndex(x => x.dateAdded === key);
        newBpList[indexToDel].deleted = true;
      }
    }
    setBpList(newBpList);
  }

  const [hp, setHp] = useStickyState('250', 'hp');
  const [partOfBody, setPartOfBody] = useStickyState('chest', 'partOfBody');
  const [chartType, setChartType] = useStickyState('ttk', 'chartType');

  if (justOpened) return (
    <Spinner animation="grow" />
  );

  return (
    <GlobalContext.Provider value={{ bpList: convertEntireUrlStrToBpListWithBpName(shareId), updateBpList, visitCount, hp, setHp, partOfBody, setPartOfBody, chartType, setChartType }}>
      <NavBar></NavBar>

      <Container fluid>
        <Row>
          <Col
            id="left-bar"
            sm={{ order: 'first', span: 12 }}
            md={{ order: 'first', span: 3 }}
          >
            {/* <AttachmentStats></AttachmentStats> */}
            {/* <InputGunDropdowns></InputGunDropdowns> */}
            {/* <InputGunButtons></InputGunButtons> */}
            {/* <InputGunBar></InputGunBar> */}
            {/* <div id="sort-list"></div> */}
            <Dropdown.Divider></Dropdown.Divider>
            <h5>Shared Blueprints:</h5>
            <GunList closeButton={false}></GunList>
            <ShareUrlBar bpList={convertEntireUrlStrToBpListWithBpName(shareId)}></ShareUrlBar>
            {/* <DeleteAllButton></DeleteAllButton> */}
          </Col>

          <Col id="right-bar">
            <Settings></Settings>
            <DamageChartArea id="damage-chart-area"></DamageChartArea>
            <StatsTable finalStatsList={generateAllStatsWithAttachments(bpList)}></StatsTable>
          </Col>

        </Row>


      </Container>
    </GlobalContext.Provider>
  )
}

export default Share;
