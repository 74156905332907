import React, { useContext } from 'react';
import { Button, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import GlobalContext from './GlobalContext';

function PartOfBodySettings(props) {
  const { setPartOfBody, partOfBody } = useContext(GlobalContext);

  const partOfBodySettings = [
    { name: 'Head', value: 'head' },
    { name: 'Chest', value: 'chest' },
    { name: 'Stomach', value: 'stomach' },
    { name: 'Arms/Legs', value: 'legs' },
  ];

  return (
    <ButtonGroup toggle className="setting-buttons">

      {partOfBodySettings.map((partOfBodySettings, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="outline-secondary"
          name="radio"
          value={partOfBodySettings.value}
          checked={partOfBody === partOfBodySettings.value}
          onChange={(e) => setPartOfBody(e.currentTarget.value)}
        >
          {partOfBodySettings.name}
        </ToggleButton>
      ))}
    </ButtonGroup>

  );
}

export default PartOfBodySettings;