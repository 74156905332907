import guns from './data/guns.js';
import attachments from './data/attachments.js';
import _ from 'lodash';

const exampleNewBp = {
  gun: 'Bruen Mk9',
  attachments: ['Monolithic Suppressor', 'XRK Summit 26.8', 'Tac Laser'],
  bpName: 'Bruen Mk9 1',
  dateAdded: 1598466881466,
  deleted: false,
};

const flattenAttachments = (gunName) => {
  let result = [];
  if (!attachments[gunName]) return result;

  const attachmentList = Object.keys(attachments[gunName]);
  for (var i = 0; i < attachmentList.length; i++) {
    result = result.concat(attachments[gunName][attachmentList[i]]);
  }

  return result;
}

function convertToOldBp({ gun, attachments, bpName, dateAdded, deleted }) {
  const oldGun = JSON.parse(JSON.stringify(_.find(guns, x => x.name === gun)));
  oldGun.bpName = bpName;
  oldGun.dateAdded = dateAdded;
  oldGun.deleted = deleted;

  const allAttachmentsForGun = flattenAttachments(gun);
  const oldAttachments = attachments.map(name => _.find(allAttachmentsForGun, x => x.name === name));
  const oldBp = [oldGun, ...oldAttachments];

  return oldBp;
};

function convertToNewBp(oldBp) {
  const oldAttachments = JSON.parse(JSON.stringify(oldBp));
  const oldGun = oldAttachments.shift();

  const newBp = {
    gun: oldGun.name,
    attachments: oldAttachments.map(x => x.name),
    bpName: oldGun.bpName,
    dateAdded: oldGun.dateAdded,
    deleted: oldGun.deleted,
  }

  return newBp;
};

function getBaseStatsForGun(gun) {
  return JSON.parse(JSON.stringify(_.find(guns, x => x.name === gun)));
};

function getFinalStatsForBp(bp) {
  const finalStats = generateGunStatsWithAttachments(convertToOldBp(bp));
  const damageDropoffsUpdated = finalStats.baseStats.damageDropoffs.map(x => x * finalStats.baseStats.range);
  finalStats.baseStats.damageDropoffsUpdated = damageDropoffsUpdated;

  return finalStats;
};

function generateGunStatsWithAttachments(bp) {
  const linearStats = [
    'ads',
    'reload',
    'tstf',
    'magSize',
    'stf',
  ]

  const geometricStats = [
    'movement',
    'hipfireArea',
    'movementAds',
    'bulletVelocity',
    'xSway',
    'ySway',
    'vertRecoil',
    'horizRecoil',
    'range',
  ]

  const finalStatMods = {
    range: 1,
    ads: 0,
    movement: 1,
    reload: 0,
    tstf: 0,
    hipfireArea: 1,
    movementAds: 1,
    bulletVelocity: 1,
    magSize: 0,
    stf: 0,
    xSway: 1,
    ySway: 1,
    horizRecoil: 1,
    vertRecoil: 1,
  }

  let attachments = JSON.parse(JSON.stringify(bp));
  const gun = attachments.shift();

  const finalGun = JSON.parse(JSON.stringify(gun));

  attachments.forEach((x) => {
    _.forEach(x.statMods, (value, key) => {
      if (finalStatMods[key] !== undefined && linearStats.includes(key)) {
        finalStatMods[key] += value;
      } else if (finalStatMods[key] !== undefined && geometricStats.includes(key)) {
        finalStatMods[key] *= value;
      } else {
        console.log('couldnt update statMod: ', key, value);
      }
    })
  })



  _.forEach(finalStatMods, (value, key) => {
    if (finalGun.baseStats[key] !== undefined && linearStats.includes(key)) {
      finalGun.baseStats[key] += value;
    } else if (finalGun.baseStats[key] !== undefined && geometricStats.includes(key)) {
      finalGun.baseStats[key] *= value;
    } else {
      finalGun.baseStats[key] = value;
    }
  })

  return finalGun;
};

export { convertToOldBp, convertToNewBp, getFinalStatsForBp, getBaseStatsForGun, generateGunStatsWithAttachments };
