import React, { useContext } from 'react';
import GlobalContext from './GlobalContext';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

function generateTableRow(finalStats) {
  const baseStats = finalStats.baseStats;
  const range = Math.round((baseStats.range - 1) * 1000) / 10;
  const movement = Math.round(baseStats.movement / 5000 * 1000) / 10;
  const movementAds = Math.round(baseStats.movementAds / 5000 * 1000) / 10;

  const tableRow = {
    bpName: finalStats.bpName,
    range: (range > 0) ? '+' + range.toString() + '%' : range.toString() + '%',
    ads: baseStats.ads + ' ms',
    stf: baseStats.stf + ' ms',
    tstf: baseStats.tstf + ' ms',
    movement: movement + '%',
    movementAds: movementAds + '%',
    vertRecoil: Math.round(baseStats.vertRecoil * 100) / 100 + 'x',
    horizRecoil: Math.round(baseStats.horizRecoil * 100) / 100 + 'x',
    hipfireArea: baseStats.hipfireArea,
    bulletVelocity: Math.round(baseStats.bulletVelocity) + ' m/s',
    reload: Math.round(baseStats.reload / 10) / 100 + 's',
    magSize: baseStats.magSize
  };

  return tableRow;
};

const columns = [{
  dataField: 'bpName',
  text: 'Blueprint'
}, {
  dataField: 'range',
  text: 'Range'
}, {
  dataField: 'bulletVelocity',
  text: 'Bullet Velocity'
}, {
  dataField: 'ads',
  text: 'ADS'
}, {
  dataField: 'stf',
  text: 'STF'
}, {
  dataField: 'tstf',
  text: 'TSTF'
}, {
  dataField: 'movement',
  text: 'Move Speed'
}, {
  dataField: 'vertRecoil',
  text: 'Recoil'
}, {
  dataField: 'reload',
  text: 'Reload'
}];

function StatsTable(props) {
  const data = [];

  props.finalStatsList.forEach(x => data.push(generateTableRow(x)));

  return (
    <div className="horizontal-scrolling">
      <div id="stats-table">
        <BootstrapTable bootstrap4 keyField='bpName' data={data} columns={columns} />
      </div>
    </div>

  )
};

export default StatsTable;