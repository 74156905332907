import React, { useState, useContext, useCallback } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';


import GlobalContext from './GlobalContext';
import { gunList, getAttachmentTypes } from './data';
import guns from './data/guns.js';
import attachments from './data/attachments.js';
import InputGunButtons from './InputGunButtons';


function InputGunButtonsProgressBar(props) {
  return (
    <ProgressBar now={props.now} label={`${props.now} of ${props.max}`} max={props.max} />
  );
}

export default InputGunButtonsProgressBar;