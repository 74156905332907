import guns from './data/guns';
import attachments from './data/attachments';

const bpList2 = [
  // [
  //   {
  //     "dateAdded": 1598466881467,
  //     "deleted": false,
  //     "bpName": "MP5 1",
  //     "name": "MP5",
  //     "nicknames": [
  //       "mp",
  //       "5"
  //     ],
  //     "type": "SMG",
  //     "baseStats": {
  //       "damageDropoffs": [
  //         0,
  //         9,
  //         18,
  //         37
  //       ],
  //       "damage": {
  //         "0": [
  //           49,
  //           34,
  //           34,
  //           30
  //         ],
  //         "1": [
  //           36,
  //           25,
  //           25,
  //           22
  //         ],
  //         "2": [
  //           31,
  //           22,
  //           22,
  //           19
  //         ],
  //         "3": [
  //           27,
  //           19,
  //           19,
  //           17
  //         ]
  //       },
  //       "ads": 200,
  //       "movement": 4854,
  //       "reload": 2790,
  //       "tstf": 217,
  //       "hipfireArea": 7.5,
  //       "movementAds": 2960,
  //       "bulletVelocity": 537,
  //       "magSize": 30,
  //       "stf": 117,
  //       "obd": 0,
  //       "rpm": 800
  //     }
  //   }
  // ],
  // [
  //   {
  //     "dateAdded": 1598466881466,
  //     "deleted": false,
  //     "bpName": "Bruen 1",
  //     "name": "Bruen",
  //     "nicknames": [
  //       "bruen",
  //       "mk",
  //       "9"
  //     ],
  //     "type": "LMG",
  //     "baseStats": {
  //       "damageDropoffs": [
  //         0,
  //         26,
  //         56
  //       ],
  //       "damage": {
  //         "0": [
  //           46,
  //           34,
  //           31,
  //           31
  //         ],
  //         "1": [
  //           42,
  //           30,
  //           28,
  //           28
  //         ],
  //         "2": [
  //           33,
  //           24,
  //           22,
  //           22
  //         ]
  //       },
  //       "ads": 446,
  //       "movement": 4470,
  //       "reload": 5270,
  //       "tstf": 454,
  //       "hipfireArea": 27,
  //       "movementAds": 1810,
  //       "bulletVelocity": 720,
  //       "magSize": 100,
  //       "stf": 325,
  //       "obd": 67,
  //       "rpm": 750
  //     }
  //   },
  //   {
  //     "name": "Monolithic Suppressor",
  //     "type": [
  //       "muzzle"
  //     ],
  //     "nicknames": [
  //       "mono",
  //       "sup"
  //     ],
  //     "statMods": {
  //       "range": 0.1,
  //       "bulletVelocity": 0.15,
  //       "ads": 41
  //     }
  //   },
  //   {
  //     "name": "XRK Summit 26.8",
  //     "type": [
  //       "barrel"
  //     ],
  //     "nicknames": [
  //       "long"
  //     ],
  //     "statMods": {
  //       "range": 0.25,
  //       "ads": 58,
  //       "bulletVelocity": 0.33,
  //       "movement": -0.017,
  //       "verticalRecoil": -0.1,
  //       "hipfireArea": 0.51
  //     }
  //   },
  //   {
  //     "name": "Tac Laser",
  //     "type": [
  //       "laser"
  //     ],
  //     "nicknames": [],
  //     "statMods": {
  //       "ads": -58
  //     }
  //   }
  // ],
  // [
  //   {
  //     "dateAdded": 1598466881465,
  //     "deleted": false,
  //     "bpName": "AK-47 1",
  //     "name": "AK-47",
  //     "nicknames": [
  //       "ak47",
  //       "ak",
  //       "47"
  //     ],
  //     "type": "AR",
  //     "baseStats": {
  //       "damageDropoffs": [
  //         0,
  //         28
  //       ],
  //       "damage": {
  //         "0": [
  //           56,
  //           42,
  //           35,
  //           35
  //         ],
  //         "1": [
  //           51,
  //           38,
  //           32,
  //           32
  //         ]
  //       },
  //       "ads": 271,
  //       "movement": 4637,
  //       "reload": 2150,
  //       "tstf": 392,
  //       "hipfireArea": 16.5,
  //       "movementAds": 2.25,
  //       "bulletVelocity": 720,
  //       "magSize": 30,
  //       "stf": 263,
  //       "obd": 0,
  //       "rpm": 560
  //     }
  //   },
  //   {
  //     "name": "Lightweight Suppressor",
  //     "type": [
  //       "muzzle"
  //     ],
  //     "nicknames": [
  //       "light",
  //       "sup"
  //     ],
  //     "statMods": {
  //       "range": -0.2,
  //       "bulletVelocity": -0.3
  //     }
  //   },
  //   {
  //     "name": "8.1 Compact",
  //     "type": [
  //       "barrel"
  //     ],
  //     "nicknames": [
  //       "short"
  //     ],
  //     "statMods": {
  //       "range": -0.2,
  //       "ads": -50,
  //       "bulletVelocity": -0.31,
  //       "movement": 0.018,
  //       "verticalRecoil": 0.14,
  //       "hipfireArea": -0.06
  //     }
  //   },
  //   {
  //     "name": "5mW",
  //     "type": [
  //       "laser"
  //     ],
  //     "nicknames": [],
  //     "statMods": {
  //       "stf": -46,
  //       "tstf": -79,
  //       "hipfireArea": -0.56
  //     }
  //   }
  // ]
]

const bpExample = [
  {
    "name": "CR-56 AMAX",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [0, 24, 35, 47],
      "damage": {
        "0": [56, 42, 35, 35],
        "1": [51, 38, 32, 32],
        "2": [45, 33, 28, 28],
        "3": [38, 28, 24, 24]
      },
      "ads": 279,
      "movement": 4659,
      "reload": 2250,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2340,
      "bulletVelocity": 587,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 630,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "G.I. Mini Reflex (1x)",
    "type": ["optic"],
    "statMods": {
      "ads": 4
    }
  },
  {
    "name": "XRK Zodiac S440",
    "type": ["barrel"],
    "statMods": {
      "range": 1.32,
      "ads": 54,
      "movement": 0.968,
      "bulletVelocity": 1.42,
      "vertRecoil": 0.78,
      "hipfireArea": 1.68
    }
  },
  {
    "name": "Lightweight Suppressor",
    "type": ["muzzle"],
    "statMods": {
      "range": 0.8,
      "bulletVelocity": 0.7
    }
  }
];

const gunTypes = ['AR', 'LMG', 'SMG'];

const gunList = guns.map((x) => x.name);

const flattenAttachments = (gunName) => {
  let result = [];
  if (!attachments[gunName]) return result;

  const attachmentList = Object.keys(attachments[gunName]);
  for (var i = 0; i < attachmentList.length; i++) {
    result = result.concat(attachments[gunName][attachmentList[i]]);
  }

  return result;
}

const getAttachmentTypes = (gun) => {
  return Object.keys(attachments[gun]);
}

export { bpExample, bpList2, guns, gunTypes, attachments, gunList, flattenAttachments, getAttachmentTypes };