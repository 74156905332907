// URL conversion
// URL format: codgundata.com/s/01a1b2c3d4,02a1b2c3d4
// 01a1b2c3d4
// first 2 digits = gun
// rest is attachments

function numToGunName(num) {
  const map = [
    "AK-47",
    "AK-47 5.45x39mm",
    "AN-94",
    "CR-56 AMAX",
    "CR-56 AMAX M67 10-R Mags",
    "FAL",
    "FN Scar 17",
    "FR 5.56",
    "Grau 5.56",
    "Kilo 141",
    "M13",
    "M13 .300 Blackout",
    "M4A1",
    "M4A1 .458 SOCOM",
    "M4A1 9mm",
    "Oden",
    "RAM-7",
    "FR 5.56 (Warzone)",
    "Bruen Mk9",
    "Holger-26",
    "M91",
    "MG34",
    "PKM",
    "SA87",
    "FiNN LMG",
    "FiNN LMG Factory Adverse",
    "AUG",
    "AUG 5.56",
    "Fennec",
    "Fennec .45 Hollow Point 12-R Mags",
    "ISO",
    "MP5",
    "MP5 10mm",
    "MP7",
    "P90",
    "PP19 Bizon",
    "Striker-45",
    "Striker-45 .45 ACP Burst",
    "Uzi",
    "Uzi 0.41AE",
    "AS VAL",
    "AS VAL (SPP 10-R Mags)",
  ]

  return map[num];
}

function gunNameToNum(gunName) {
  const map = {
    "AK-47": 0,
    "AK-47 5.45x39mm": 1,
    "AN-94": 2,
    "CR-56 AMAX": 3,
    "CR-56 AMAX M67 10-R Mags": 4,
    "FAL": 5,
    "FN Scar 17": 6,
    "FR 5.56": 7,
    "Grau 5.56": 8,
    "Kilo 141": 9,
    "M13": 10,
    "M13 .300 Blackout": 11,
    "M4A1": 12,
    "M4A1 .458 SOCOM": 13,
    "M4A1 9mm": 14,
    "Oden": 15,
    "RAM-7": 16,
    "FR 5.56 (Warzone)": 17,
    "Bruen Mk9": 18,
    "Holger-26": 19,
    "M91": 20,
    "MG34": 21,
    "PKM": 22,
    "SA87": 23,
    "FiNN LMG": 24,
    "FiNN LMG Factory Adverse": 25,
    "AUG": 26,
    "AUG 5.56": 27,
    "Fennec": 28,
    "Fennec .45 Hollow Point 12-R Mags": 29,
    "ISO": 30,
    "MP5": 31,
    "MP5 10mm": 32,
    "MP7": 33,
    "P90": 34,
    "PP19 Bizon": 35,
    "Striker-45": 36,
    "Striker-45 .45 ACP Burst": 37,
    "Uzi": 38,
    "Uzi 0.41AE": 39,
    "AS VAL": 40,
    "AS VAL (SPP 10-R Mags)": 41,
  };

  return map[gunName];
}

function numToAttachmentName(num) {
  const map = [
    "Spetsnaz Elite",
    "23.0 RPK Barrel",
    "8.1 Compact Barrel",
    "23.0 Romanian",
    "Lightweight Suppressor",
    "Tactical Suppressor",
    "Monolithic Suppressor",
    "Muzzle Brake",
    "Compensator",
    "Operator Reflex Sight (1x)",
    "Corp Combat Holo Sight (1.5x)",
    "Aim-Op Reflex Sight (1x)",
    "Scout Combat Optic (3.25x)",
    "APX5 Holographic Sight (1.5x)",
    "Solozero Optics Mini Reflex (1x)",
    "Integral Hybrid (3.25x + 1x)",
    "VLK 3.0x Optic (3x)",
    "Sniper Scope (4.4x)",
    "Viper Reflex Sight (1x)",
    "G.I. Mini Reflex (1x)",
    "Solozero NVG Enhanced (3.25x Thermal)",
    "Monocle Reflex Sight (1x)",
    "Cronen LP945 Mini Reflex (1x)",
    "Canted Hybrid (3.25x + 1x)",
    "Cronen C480 Pro Optic (3.5x)",
    "Merc Thermal Optic (3.25x Thermal)",
    "Variable Zoom Scope (3x + 6.1x)",
    "Thermal Hybrid (3.25x Thermal + 1x)",
    "Merc Foregrip",
    "Operator Foregrip",
    "Ranger Foregrip",
    "Commando Foregrip",
    "Tactical Foregrip",
    "40 Round Mags",
    "75 Round Drum Mags",
    "Stippled Grip Tape",
    "Rubberized Grip Tape",
    "Granulated Grip Tape",
    "Skeleton Stock",
    "No Stock",
    "FSS Close Quarters Stock",
    "Field LMG Stock",
    "FORGE TAC Ultralight",
    "Tac Laser",
    "5mW Laser",
    "1mW Laser",
    "Sleight of Hand",
    "G.I. Mini Reflex",
    "APX5 Holographic",
    "4.0x Flip Hybrid (4x)",
    "PBX Holo 7 Sight (1.5x)",
    "Cronen LP945 Mini Reflex",
    "POS-M3 Thermal Scope",
    "AN-94 Sonic Brake",
    "AN-94 Factory 330mm",
    "AN-94 Factory X-438mm",
    "VLK AN-94 Sila",
    "45 Round Mags",
    "60 Round Casket Mags",
    "AN-94 Factory Heavy",
    "Folded Stock",
    "VLK PX-9 Pero",
    "FSS 8.3 Intruder",
    "XRK Zodiac S440",
    "FSS 11.8 Squall",
    "XRK CR-56 Stippled Wrap",
    "CR-56 EXO",
    "FTAC Hunter",
    "FTAC Spartan",
    "XRK Gatekeeper",
    "18.0 Ultralight",
    "XRK Marksman",
    "13.0 OSW Para",
    "24 Round Mags",
    "30 Round Mags",
    "Factory 18 Aluminum Stock",
    "FORGE TAC Stalker",
    "Forge Tac 17.2 LB",
    "Forge Tac 20.0 LB",
    "Forge Tac CQC Pro",
    "Bipod Foregrip",
    "25 Round Mags",
    "FTAC Collapsible Stock",
    "XRK Obelisk Pro",
    "FR 15.9 Commando",
    "FR 24.4 Sniper",
    "50 Round Mag",
    "60 Round Mag",
    "FSS Tac-Wrap",
    "FR Ultralight Hollow",
    "FORGE TAC Ballast Pack",
    "ZLR Drifter A08",
    "Tempus 26.4 Archangel",
    "XRK CZEN mk2",
    "FFS 20.8 Nexus",
    "Cronen Shark II",
    "50 Round Mags",
    "60 Round Mags",
    "XRK Void II",
    "Cronen Sniper Elite",
    "FTAC Rubber",
    "FSS Blackjack",
    "XRK StrikeLite III",
    "Singuard Arms 16.6 SOCOM",
    "Singuard Arms 19.8 Prowler",
    "Singuard Arms Whisper",
    "100 Round Drums",
    "Singuard Arms Sniper Pro",
    "Tempus Mini",
    "Tempus Cyclone",
    "Tempus Marksman",
    "M13 Skeleton Stock",
    "FSS 11.5 Commando",
    "Stock M16 Grenadier",
    "FSS 14.5 Tac Lite",
    "Corvus Custom Marksman",
    "FSS 12.4 Predator",
    "FORGE TAC CQS",
    "M-16 Stock",
    "Singuard Arms Invader",
    "Oden Factory 810mm",
    "Oden Factory 730mm",
    "Oden Factory 420mm",
    "Colossus Suppressor",
    "FTAC XL Elite Comb",
    "Oden Ultralight Hollow",
    "FTAC 13.5 Compact",
    "Forge Tac Eclipse",
    "FSS Ranger",
    "Cronen 2x2 Elite",
    "XRK Close Quarters Stock",
    "FTAC Equilibrium",
    "XRK Ultralight Hollow",
    "XRK Horizon 23.0",
    "XRK Summit 26.8",
    "Bruen 18.0 Para",
    "200 Round Belt",
    "XRK Ultralight",
    "FTAC 8.98 Spitfire",
    "FSS Integral Reflex",
    "Solozero K498 4.0x Integral",
    "XRK Axis",
    "FSS Infantry",
    "M91 Special Forces",
    "M91 Infantry",
    "M91 Heavy",
    "120 Round Belt",
    "150 Round Belt",
    "XRK Striker",
    "FSS Brute",
    "FSS Stubby",
    "FSS Elite",
    "75 Round Belt",
    "100 Round Belt",
    "18.2 Compact Barrel",
    "26.9 Extended Barrel",
    "25.9 Heavy Barrel",
    "Snatch Grip",
    "SA87 18.2 Factory",
    "SA87 25.4 Factory",
    "SA87 12.4 Factory",
    "Forge Tac CQB Comb",
    "XRK SA87 Heavy Stock Pro",
    "SA87 Ultralight Hollow",
    "XRK Pro Twist",
    "XRK LongShot Advantage",
    "FTAC VC-8 Harrier",
    "5.56 NATO 100-Round Belts",
    "5.56 CT 75-Round Belts",
    "5.56 CT 100-Round Belts",
    "XRK Citadel",
    "XRK M309 Forward Assault",
    "XRK ChainSAW",
    "Factory Adverse (Default)",
    "XRK Pro Twist Adverse",
    "XRK LongShot Adverse",
    "FLTAC VC-8 Harrier Adverse",
    "407mm Extended",
    "407mm Lightweight",
    "622mm Long Barrel",
    "Integral 3.0x Optic (3x)",
    "32 Round Mags",
    "FORGE TAC CQB Comb",
    "FTAC Ultralight Hollow",
    "FSS Heavy Stock Pro",
    "60 Round Drum",
    "ZLR Sabre",
    "ZLR 16 Apex",
    "ZLR 18 Deadfall",
    "40 Round Drum Mags",
    "FTAC C6 Carbine PRO",
    "ZLR Blade",
    "FTAC 225 Dominator",
    "FSS Revolution",
    "ISO 140mm CQB",
    "FSS Nightshade",
    "50 Round Drums",
    "FSS Vice ISO Grip",
    "FTAC Elite ISO Grip",
    "ISO Tac-Form",
    "FTAC Vagrant",
    "ISO Collapsible",
    "FSS Light",
    "Monolithic Integral Suppressor",
    "FSS Mini",
    "Subsonic Integral Suppressor",
    "FTAC Collapsible",
    "Classic Straight-line Stock",
    "FSS Strike",
    "FSS Recon",
    "FSS Swat",
    "FSS 10.6 Pro",
    "Forge Tac Retribution",
    "FSS Ring Sight",
    "Stippled Grip Tape 1",
    "Rubberized Grip Tape 1",
    "Granulated Grip Tape 1",
    "Fly Strap",
    "8.7 Steel",
    "8.7 Polygonal",
    "8.7 Aluminum",
    "Corvus Skeleton Stock",
    "Factory Aluminum Stock",
    "84 Round Helical Mags",
    "300mm Poly Barrel",
    "400mm Stainless Steel",
    "150mm Stainless Steel",
    "FTAC G-5 EXO",
    "XRK Gen III Survivalist Series",
    "FSS Guardian",
    "FTAC Precision Fixed Stock",
    "13.1 First Responder",
    "8.5 Factory Mini",
    "16.5 Factory Carbine",
    "FSS Carbine Pro",
    "Standard-Issue Wood Stock",
    "Dragunov Scope (5.5x)",
    "Variable Zoom Scope (3x + 6x)",
    "VLK 105mm Stova",
    "Stovl SOF",
    "VLK 200mm Osa",
    "Bipod (Crouched)",
    "Bipod (Prone)",
    "VLK Vintazh",
    "FSS Intl. Gen 4 GRU",
    "Stovl 6P30 Skelet",
    "VLK Strelok",
  ]

  return map[num];
}

function attachmentNameToNum(attachmentName) {
  const map = {
    "Spetsnaz Elite": 0,
    "23.0 RPK Barrel": 1,
    "8.1 Compact Barrel": 2,
    "23.0 Romanian": 3,
    "Lightweight Suppressor": 4,
    "Tactical Suppressor": 5,
    "Monolithic Suppressor": 6,
    "Muzzle Brake": 7,
    "Compensator": 8,
    "Operator Reflex Sight (1x)": 9,
    "Corp Combat Holo Sight (1.5x)": 10,
    "Aim-Op Reflex Sight (1x)": 11,
    "Scout Combat Optic (3.25x)": 12,
    "APX5 Holographic Sight (1.5x)": 13,
    "Solozero Optics Mini Reflex (1x)": 14,
    "Integral Hybrid (3.25x + 1x)": 15,
    "VLK 3.0x Optic (3x)": 16,
    "Sniper Scope (4.4x)": 17,
    "Viper Reflex Sight (1x)": 18,
    "G.I. Mini Reflex (1x)": 19,
    "Solozero NVG Enhanced (3.25x Thermal)": 20,
    "Monocle Reflex Sight (1x)": 21,
    "Cronen LP945 Mini Reflex (1x)": 22,
    "Canted Hybrid (3.25x + 1x)": 23,
    "Cronen C480 Pro Optic (3.5x)": 24,
    "Merc Thermal Optic (3.25x Thermal)": 25,
    "Variable Zoom Scope (3x + 6.1x)": 26,
    "Thermal Hybrid (3.25x Thermal + 1x)": 27,
    "Merc Foregrip": 28,
    "Operator Foregrip": 29,
    "Ranger Foregrip": 30,
    "Commando Foregrip": 31,
    "Tactical Foregrip": 32,
    "40 Round Mags": 33,
    "75 Round Drum Mags": 34,
    "Stippled Grip Tape": 35,
    "Rubberized Grip Tape": 36,
    "Granulated Grip Tape": 37,
    "Skeleton Stock": 38,
    "No Stock": 39,
    "FSS Close Quarters Stock": 40,
    "Field LMG Stock": 41,
    "FORGE TAC Ultralight": 42,
    "Tac Laser": 43,
    "5mW Laser": 44,
    "1mW Laser": 45,
    "Sleight of Hand": 46,
    "G.I. Mini Reflex": 47,
    "APX5 Holographic": 48,
    "4.0x Flip Hybrid (4x)": 49,
    "PBX Holo 7 Sight (1.5x)": 50,
    "Cronen LP945 Mini Reflex": 51,
    "POS-M3 Thermal Scope": 52,
    "AN-94 Sonic Brake": 53,
    "AN-94 Factory 330mm": 54,
    "AN-94 Factory X-438mm": 55,
    "VLK AN-94 Sila": 56,
    "45 Round Mags": 57,
    "60 Round Casket Mags": 58,
    "AN-94 Factory Heavy": 59,
    "Folded Stock": 60,
    "VLK PX-9 Pero": 61,
    "FSS 8.3 Intruder": 62,
    "XRK Zodiac S440": 63,
    "FSS 11.8 Squall": 64,
    "XRK CR-56 Stippled Wrap": 65,
    "CR-56 EXO": 66,
    "FTAC Hunter": 67,
    "FTAC Spartan": 68,
    "XRK Gatekeeper": 69,
    "18.0 Ultralight": 70,
    "XRK Marksman": 71,
    "13.0 OSW Para": 72,
    "24 Round Mags": 73,
    "30 Round Mags": 74,
    "Factory 18 Aluminum Stock": 75,
    "FORGE TAC Stalker": 76,
    "Forge Tac 17.2 LB": 77,
    "Forge Tac 20.0 LB": 78,
    "Forge Tac CQC Pro": 79,
    "Bipod Foregrip": 80,
    "25 Round Mags": 81,
    "FTAC Collapsible Stock": 82,
    "XRK Obelisk Pro": 83,
    "FR 15.9 Commando": 84,
    "FR 24.4 Sniper": 85,
    "50 Round Mag": 86,
    "60 Round Mag": 87,
    "FSS Tac-Wrap": 88,
    "FR Ultralight Hollow": 89,
    "FORGE TAC Ballast Pack": 90,
    "ZLR Drifter A08": 91,
    "Tempus 26.4 Archangel": 92,
    "XRK CZEN mk2": 93,
    "FFS 20.8 Nexus": 94,
    "Cronen Shark II": 95,
    "50 Round Mags": 96,
    "60 Round Mags": 97,
    "XRK Void II": 98,
    "Cronen Sniper Elite": 99,
    "FTAC Rubber": 100,
    "FSS Blackjack": 101,
    "XRK StrikeLite III": 102,
    "Singuard Arms 16.6 SOCOM": 103,
    "Singuard Arms 19.8 Prowler": 104,
    "Singuard Arms Whisper": 105,
    "100 Round Drums": 106,
    "Singuard Arms Sniper Pro": 107,
    "Tempus Mini": 108,
    "Tempus Cyclone": 109,
    "Tempus Marksman": 110,
    "M13 Skeleton Stock": 111,
    "FSS 11.5 Commando": 112,
    "Stock M16 Grenadier": 113,
    "FSS 14.5 Tac Lite": 114,
    "Corvus Custom Marksman": 115,
    "FSS 12.4 Predator": 116,
    "FORGE TAC CQS": 117,
    "M-16 Stock": 118,
    "Singuard Arms Invader": 119,
    "Oden Factory 810mm": 120,
    "Oden Factory 730mm": 121,
    "Oden Factory 420mm": 122,
    "Colossus Suppressor": 123,
    "FTAC XL Elite Comb": 124,
    "Oden Ultralight Hollow": 125,
    "FTAC 13.5 Compact": 126,
    "Forge Tac Eclipse": 127,
    "FSS Ranger": 128,
    "Cronen 2x2 Elite": 129,
    "XRK Close Quarters Stock": 130,
    "FTAC Equilibrium": 131,
    "XRK Ultralight Hollow": 132,
    "XRK Horizon 23.0": 133,
    "XRK Summit 26.8": 134,
    "Bruen 18.0 Para": 135,
    "200 Round Belt": 136,
    "XRK Ultralight": 137,
    "FTAC 8.98 Spitfire": 138,
    "FSS Integral Reflex": 139,
    "Solozero K498 4.0x Integral": 140,
    "XRK Axis": 141,
    "FSS Infantry": 142,
    "M91 Special Forces": 143,
    "M91 Infantry": 144,
    "M91 Heavy": 145,
    "120 Round Belt": 146,
    "150 Round Belt": 147,
    "XRK Striker": 148,
    "FSS Brute": 149,
    "FSS Stubby": 150,
    "FSS Elite": 151,
    "75 Round Belt": 152,
    "100 Round Belt": 153,
    "18.2 Compact Barrel": 154,
    "26.9 Extended Barrel": 155,
    "25.9 Heavy Barrel": 156,
    "Snatch Grip": 157,
    "SA87 18.2 Factory": 158,
    "SA87 25.4 Factory": 159,
    "SA87 12.4 Factory": 160,
    "Forge Tac CQB Comb": 161,
    "XRK SA87 Heavy Stock Pro": 162,
    "SA87 Ultralight Hollow": 163,
    "XRK Pro Twist": 164,
    "XRK LongShot Advantage": 165,
    "FTAC VC-8 Harrier": 166,
    "5.56 NATO 100-Round Belts": 167,
    "5.56 CT 75-Round Belts": 168,
    "5.56 CT 100-Round Belts": 169,
    "XRK Citadel": 170,
    "XRK M309 Forward Assault": 171,
    "XRK ChainSAW": 172,
    "Factory Adverse (Default)": 173,
    "XRK Pro Twist Adverse": 174,
    "XRK LongShot Adverse": 175,
    "FLTAC VC-8 Harrier Adverse": 176,
    "407mm Extended": 177,
    "407mm Lightweight": 178,
    "622mm Long Barrel": 179,
    "Integral 3.0x Optic (3x)": 180,
    "32 Round Mags": 181,
    "FORGE TAC CQB Comb": 182,
    "FTAC Ultralight Hollow": 183,
    "FSS Heavy Stock Pro": 184,
    "60 Round Drum": 185,
    "ZLR Sabre": 186,
    "ZLR 16 Apex": 187,
    "ZLR 18 Deadfall": 188,
    "40 Round Drum Mags": 189,
    "FTAC C6 Carbine PRO": 190,
    "ZLR Blade": 191,
    "FTAC 225 Dominator": 192,
    "FSS Revolution": 193,
    "ISO 140mm CQB": 194,
    "FSS Nightshade": 195,
    "50 Round Drums": 196,
    "FSS Vice ISO Grip": 197,
    "FTAC Elite ISO Grip": 198,
    "ISO Tac-Form": 199,
    "FTAC Vagrant": 200,
    "ISO Collapsible": 201,
    "FSS Light": 202,
    "Monolithic Integral Suppressor": 203,
    "FSS Mini": 204,
    "Subsonic Integral Suppressor": 205,
    "FTAC Collapsible": 206,
    "Classic Straight-line Stock": 207,
    "FSS Strike": 208,
    "FSS Recon": 209,
    "FSS Swat": 210,
    "FSS 10.6 Pro": 211,
    "Forge Tac Retribution": 212,
    "FSS Ring Sight": 213,
    "Stippled Grip Tape 1": 214,
    "Rubberized Grip Tape 1": 215,
    "Granulated Grip Tape 1": 216,
    "Fly Strap": 217,
    "8.7 Steel": 218,
    "8.7 Polygonal": 219,
    "8.7 Aluminum": 220,
    "Corvus Skeleton Stock": 221,
    "Factory Aluminum Stock": 222,
    "84 Round Helical Mags": 223,
    "300mm Poly Barrel": 224,
    "400mm Stainless Steel": 225,
    "150mm Stainless Steel": 226,
    "FTAC G-5 EXO": 227,
    "XRK Gen III Survivalist Series": 228,
    "FSS Guardian": 229,
    "FTAC Precision Fixed Stock": 230,
    "13.1 First Responder": 231,
    "8.5 Factory Mini": 232,
    "16.5 Factory Carbine": 233,
    "FSS Carbine Pro": 234,
    "Standard-Issue Wood Stock": 235,
    "Dragunov Scope (5.5x)": 236,
    "Variable Zoom Scope (3x + 6x)": 237,
    "VLK 105mm Stova": 238,
    "Stovl SOF": 239,
    "VLK 200mm Osa": 240,
    "Bipod (Crouched)": 241,
    "Bipod (Prone)": 242,
    "VLK Vintazh": 243,
    "FSS Intl. Gen 4 GRU": 244,
    "Stovl 6P30 Skelet": 245,
    "VLK Strelok": 246,
  }

  return map[attachmentName];
}

function bpStrToArray(str) {
  const result = [];

  if (str.length < 2 || str.length % 2 !== 0) return null;

  const gunNum = parseInt(str.substring(0, 2), 36);
  result.push(numToGunName(gunNum));

  for (var i = 2; i < str.length; i += 2) {
    result.push(numToAttachmentName(parseInt(str.substring(i, i + 2), 36)));
  }

  return result;
}

function bpObjToUrlStr(bp) {
  let result = '';
  let gunStr = gunNameToNum(bp.gun).toString(36).padStart(2, "0");
  result += gunStr;

  for (var i = 0; i < bp.attachments.length; i++) {
    result += attachmentNameToNum(bp.attachments[i]).toString(36).padStart(2, "0");
  }

  return result;
}

function urlStrToBpObj(urlStr) {
  let attachments = bpStrToArray(urlStr);
  let gun = attachments.shift();

  let result = {
    gun: gun,
    attachments: attachments,
  }

  return result;
}

function convertEntireUrlStrToBpList(urlStr) {
  const timeNow = Date.now();

  const urlStrList = urlStr.split(',');
  const bpList = urlStrList.map((x, i) => {
    const bpObj = urlStrToBpObj(x);
    bpObj.deleted = false;
    bpObj.dateAdded = timeNow - i;

    return bpObj;
  });

  return bpList;
}

function convertBpListToEntireUrlStr(bpList) {
  const array = bpList.map(x => bpObjToUrlStr(x));
  return array.join(',');
}

function addBpNameToBpList(bpList) {
  const newBpList = JSON.parse(JSON.stringify(bpList)).reverse();

  const counts = {};
  const result = newBpList.map(bp => {
    const newBp = JSON.parse(JSON.stringify(bp));
    counts[bp.gun] ? counts[bp.gun] = counts[bp.gun] + 1 : counts[bp.gun] = 1;
    const bpName = bp.gun + ' copy ' + counts[bp.gun];
    newBp.bpName = bpName;
    return newBp;
  });

  return result.reverse();
}

function convertEntireUrlStrToBpListWithBpName(urlStr) {
  return addBpNameToBpList(convertEntireUrlStrToBpList(urlStr));
}

export { convertEntireUrlStrToBpListWithBpName, convertBpListToEntireUrlStr };