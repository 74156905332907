
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Settings
import HpSettings from './HpSettings';
import PartOfBodySettings from './PartOfBodySettings';
import ChartTypeSettings from './ChartTypeSettings';

function Settings() {
  return (
    <Container id="settings-area" >
      <Row className="justify-content-md-center">
        <Col xs={12} md={3}>
          <ChartTypeSettings></ChartTypeSettings>
        </Col>
        <Col xs={12} md={3}>
          <HpSettings></HpSettings>
        </Col>
        <Col xs={12} md={6}>
          <PartOfBodySettings></PartOfBodySettings>
        </Col>
      </Row>
    </Container>
  )
}

export default Settings;