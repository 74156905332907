import React, { useState, useContext, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';


import GlobalContext from './GlobalContext';
import { gunList, getAttachmentTypes, gunTypes } from './data';
import guns from './data/guns.js';
import attachments from './data/attachments.js';

import InputGunButtonsProgressBar from './InputGunButtonsProgressBar';

const badgeVariant = {
  AR: "primary",
  LMG: "danger",
  SMG: "warning"
};

function InputGunDropdowns() {
  const defaultAttachmentsSelection = {
    ammo: null,
    barrel: null,
    muzzle: null,
    underbarrel: null,
    laser: null,
    optic: null,
    stock: null,
    "rear grip": null,
    perk: null,
  };

  const { updateBpList } = useContext(GlobalContext);
  const [step, setStep] = useState(0);
  const maxStep = 4;
  const [gunSelection, setGunSelection] = useState(null);
  const [gunTypeSelection, setGunTypeSelection] = useState(null);
  const [attachmentsSelection, setAttachmentSelection] = useState(defaultAttachmentsSelection);
  const [formIsValid, setFormIsValid] = useState(false);

  function startOver(step = 1) {
    setStep(step);
    setGunSelection(null);
    setGunTypeSelection(null);
    setAttachmentSelection(defaultAttachmentsSelection);
    setFormIsValid(false);
  };

  function renderZerothStep() {
    return (<Button block onClick={e => setStep(1)}>Add a Blueprint</Button>);
  }

  function getAttachmentsSelectionAsArray(attachmentsSelection) {
    const result = [];
    Object.keys(attachmentsSelection).forEach(slot => {
      if (attachmentsSelection[slot] && attachmentsSelection[slot] !== '') {
        result.push(attachmentsSelection[slot]);
      }
    })

    return result;
  };

  function getFormValidity(gunSelection, attachmentsSelection) {
    console.log(gunSelection, attachmentsSelection, formIsValid);
    return (gunList.includes(gunSelection) && getAttachmentsSelectionAsArray(attachmentsSelection).length <= 5);
  }

  function renderGunTypes() {
    return gunTypes.map(type => (<Button block onClick={event => {
      setGunTypeSelection(type);
      setStep(2);
    }}>{type}</Button>))
  }

  function renderGunList(type) {
    return guns
      .filter(gun => gun.type === type)
      .map(gun => gun.name)
      .sort()
      .map(name => {
        return (
          <Button
            block
            onClick={event => {
              setGunSelection(name);
              setFormIsValid(true);
              setStep(3);
            }}
            variant="primary"
            key={name}>
            {name}
          </Button>
        )
      });
  }

  function renderAttachmentSelection(gun) {
    if (!gun) return (<></>);

    const attachmentTypes = Object.keys(attachments[gun]);

    function renderOptions(gun, type) {
      const attachmentNames = attachments[gun][type]
        .map(attachment => attachment.name)
        .sort();



      return attachmentNames.map(x => <option value={JSON.stringify([type, x])}>{x}</option>)

    }

    return attachmentTypes.map(type => {
      return (
        <Form.Group>
          <Form.Control as="select"
            size="sm"
            onChange={(e) => {
              const value = JSON.parse(e.target.value);
              const type = value[0];
              const attachmentName = value[1];
              console.log(type, attachmentName);
              const newAttachmentSelection = JSON.parse(JSON.stringify(attachmentsSelection));
              newAttachmentSelection[type] = attachmentName;
              setAttachmentSelection(newAttachmentSelection);
              if (getFormValidity(gunSelection, newAttachmentSelection)) setFormIsValid(true);
              else setFormIsValid(false);
            }}>
            <option value={JSON.stringify([type, null])}>-- {type} --</option>
            {renderOptions(gun, type)}
          </Form.Control>
        </Form.Group>
      )
    })
  }

  function handleSubmit(gunSelection, attachmentsSelection) {
    const bpTemplate = {
      gun: gunSelection,
      attachments: getAttachmentsSelectionAsArray(attachmentsSelection),
    };
    updateBpList(bpTemplate);
    setStep(4);
  }

  function renderAttachmentSelectionStep(gunSelection) {

    return (
      <>
        {renderAttachmentSelection(gunSelection)}
        <Button disabled={!formIsValid} onClick={(e) => handleSubmit(gunSelection, attachmentsSelection)}>{getAttachmentsSelectionAsArray(attachmentsSelection).length <= 5 ? "Submit" : "Too many attachments"}</Button>
      </>
    )
  }

  function renderReceiptStep() {
    return (
      <>
        <div>
          <h5>Your new Blueprint has been added to the list below.</h5>
        </div>
        <Button onClick={e => startOver(1)} block>Add another blueprint</Button>
        <Button onClick={e => startOver(0)} block>Done adding</Button>
        <Button onClick={e => setStep(3)} block>Go back to add similar Blueprint</Button>
        This takes you back to the previous step to alter attachments so you can submit another {gunSelection} with different attachments.
      </>
    )
  }


  switch (step) {
    case 0:
      return (
        <div id="input-gun-buttons">
          {renderZerothStep()}
        </div>
      )
    case 1:
      return (
        <div id="input-gun-buttons">
          <InputGunButtonsProgressBar now={step} max={maxStep}></InputGunButtonsProgressBar>
          <h5>To add a blueprint, first, select a gun type:</h5>
          {renderGunTypes()}
        </div>
      )
      break;
    case 2:
      return (
        <div id="input-gun-buttons">
          <InputGunButtonsProgressBar now={step} max={maxStep}></InputGunButtonsProgressBar>
          <h5>Second, select the gun:</h5>
          {renderGunList(gunTypeSelection)}
        </div>
      )
      break;
    case 3:
      return (
        <div id="input-gun-buttons">
          <InputGunButtonsProgressBar now={step} max={maxStep}></InputGunButtonsProgressBar>
          <h5>Now add the attachments you want on {gunSelection}:</h5>
          {renderAttachmentSelectionStep(gunSelection)}
        </div>
      )
      break;
    case 4:
      return (
        <div id="input-gun-buttons">
          <InputGunButtonsProgressBar now={step} max={maxStep}></InputGunButtonsProgressBar>
          {renderReceiptStep()}
        </div>
      )
      break;
    default:
      return (<></>);
  }


  // return (
  //   <div id="input-gun-buttons">
  //     <InputGunButtonsProgressBar now={step} max={maxStep}></InputGunButtonsProgressBar>
  //     Step: {step}
  //     <br />
  //     {renderGunTypes()}
  //     {renderGunList(gunTypeSelection)}
  //     {renderAttachmentSelectionStep(gunSelection)}
  //     {renderReceiptStep()}
  //   </div>
  // )
}

export default InputGunDropdowns;