export default [
  {
    "name": "AK-47",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        27.5
      ],
      "damage": {
        "0": [
          56,
          42,
          35,
          35
        ],
        "1": [
          51,
          38,
          32,
          32
        ]
      },
      "ads": 271,
      "movement": 4637,
      "reload": 2150,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2250,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 544,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "AK-47 5.45x39mm",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        17,
        25.5,
        36,
        50
      ],
      "damage": {
        "0": [
          55,
          40,
          34,
          34
        ],
        "1": [
          50,
          37,
          31,
          31
        ],
        "2": [
          40,
          30,
          25,
          25
        ],
        "3": [
          35,
          26,
          22,
          22
        ],
        "4": [
          32,
          24,
          20,
          20
        ]
      },
      "ads": 271,
      "movement": 4637,
      "reload": 2150,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2250,
      "bulletVelocity": 587,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 665,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "AN-94",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        21.8,
        33.8,
        42.5
      ],
      "damage": {
        "0": [
          46,
          34,
          31,
          29
        ],
        "1": [
          38,
          28,
          26,
          24
        ],
        "2": [
          35,
          26,
          24,
          22
        ],
        "3": [
          29,
          21,
          19,
          18
        ]
      },
      "ads": 254,
      "movement": 4660,
      "reload": 2250,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2270,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 680,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "CR-56 AMAX",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        24,
        35,
        47
      ],
      "damage": {
        "0": [
          56,
          42,
          35,
          35
        ],
        "1": [
          51,
          38,
          32,
          32
        ],
        "2": [
          45,
          33,
          28,
          28
        ],
        "3": [
          38,
          28,
          24,
          24
        ]
      },
      "ads": 279,
      "movement": 4659,
      "reload": 2250,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2340,
      "bulletVelocity": 587,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 630,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "CR-56 AMAX M67 10-R Mags",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        25,
        54
      ],
      "damage": {
        "0": [
          100,
          57,
          52,
          48
        ],
        "1": [
          94,
          54,
          49,
          45
        ],
        "2": [
          73,
          42,
          38,
          35
        ]
      },
      "ads": 250,
      "movement": 4659,
      "reload": 2250,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2340,
      "bulletVelocity": 812,
      "magSize": 10,
      "stf": 263,
      "obd": 0,
      "rpm": 285,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "FAL",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        20,
        43
      ],
      "damage": {
        "0": [
          100,
          58,
          49,
          49
        ],
        "1": [
          92,
          54,
          45,
          45
        ],
        "2": [
          69,
          40,
          34,
          34
        ]
      },
      "ads": 254,
      "movement": 4643,
      "reload": 2600,
      "tstf": 392,
      "hipfireArea": 20,
      "movementAds": 2380,
      "bulletVelocity": 720,
      "magSize": 20,
      "stf": 263,
      "obd": 0,
      "rpm": 420,
      "xSway": 2.5,
      "ySway": 2.2,
      "period": 2.2
    }
  },
  {
    "name": "FN Scar 17",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        33
      ],
      "damage": {
        "0": [
          56,
          35,
          35,
          35
        ],
        "1": [
          51,
          32,
          32,
          32
        ]
      },
      "ads": 296,
      "movement": 4627,
      "reload": 2330,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2200,
      "bulletVelocity": 720,
      "magSize": 20,
      "stf": 263,
      "obd": 0,
      "rpm": 573,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "FR 5.56",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        31
      ],
      "damage": {
        "0": [
          64,
          40,
          40,
          40
        ],
        "1": [
          51,
          32,
          32,
          32
        ]
      },
      "ads": 296,
      "movement": 4600,
      "reload": 2370,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2360,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 475,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "Grau 5.56",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        31,
        48.5
      ],
      "damage": {
        "0": [
          42,
          28,
          28,
          28
        ],
        "1": [
          34,
          22,
          22,
          22
        ],
        "2": [
          27,
          18,
          18,
          18
        ]
      },
      "ads": 221,
      "movement": 4780,
      "reload": 2150,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2610,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 730,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "Kilo 141",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        30.5,
        54.5
      ],
      "damage": {
        "0": [
          42,
          28,
          28,
          28
        ],
        "1": [
          34,
          23,
          23,
          23
        ],
        "2": [
          27,
          18,
          18,
          18
        ]
      },
      "ads": 238,
      "movement": 4659,
      "reload": 2360,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2360,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 751,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "M13",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        37
      ],
      "damage": {
        "0": [
          36,
          24,
          24,
          24
        ],
        "1": [
          28,
          19,
          19,
          19
        ]
      },
      "ads": 238,
      "movement": 4671,
      "reload": 2370,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2390,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 890,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "M13 .300 Blackout",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        41
      ],
      "damage": {
        "0": [
          36,
          24,
          24,
          24
        ],
        "1": [
          28,
          19,
          19,
          19
        ]
      },
      "ads": 238,
      "movement": 4671,
      "reload": 2370,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2390,
      "bulletVelocity": 537,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 890,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "M4A1",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        29,
        50
      ],
      "damage": {
        "0": [
          42,
          28,
          28,
          28
        ],
        "1": [
          33,
          22,
          22,
          22
        ],
        "2": [
          27,
          18,
          18,
          18
        ]
      },
      "ads": 238,
      "movement": 4703,
      "reload": 2230,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2480,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 808,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "M4A1 .458 SOCOM",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        35
      ],
      "damage": {
        "0": [
          72,
          48,
          48,
          48
        ],
        "1": [
          61,
          41,
          41,
          41
        ]
      },
      "ads": 238,
      "movement": 4703,
      "reload": 2230,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2480,
      "bulletVelocity": 834,
      "magSize": 10,
      "stf": 263,
      "obd": 0,
      "rpm": 590,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "M4A1 9mm",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        16,
        18.5,
        27.5
      ],
      "damage": {
        "0": [
          40,
          25,
          25,
          22
        ],
        "1": [
          32,
          20,
          20,
          18
        ],
        "2": [
          29,
          18,
          18,
          16
        ],
        "3": [
          25,
          16,
          16,
          14
        ]
      },
      "ads": 238,
      "movement": 4703,
      "reload": 2230,
      "tstf": 392,
      "hipfireArea": 8.5,
      "movementAds": 2480,
      "bulletVelocity": 576,
      "magSize": 32,
      "stf": 263,
      "obd": 0,
      "rpm": 992,
      "xSway": 2.2,
      "ySway": 2.5,
      "period": 1.75
    }
  },
  {
    "name": "Oden",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        42.5
      ],
      "damage": {
        "0": [
          77,
          62,
          48,
          48
        ],
        "1": [
          58,
          46,
          36,
          36
        ]
      },
      "ads": 346,
      "movement": 4591,
      "reload": 2570,
      "tstf": 392,
      "hipfireArea": 18,
      "movementAds": 2130,
      "bulletVelocity": 720,
      "magSize": 20,
      "stf": 263,
      "obd": 0,
      "rpm": 413,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "RAM-7",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        28,
        45
      ],
      "damage": {
        "0": [
          42,
          28,
          28,
          28
        ],
        "1": [
          34,
          23,
          23,
          23
        ],
        "2": [
          27,
          18,
          18,
          18
        ]
      },
      "ads": 217,
      "movement": 4703,
      "reload": 2400,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2670,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 855,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "FR 5.56 (Warzone)",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        50
      ],
      "damage": {
        "0": [
          64,
          40,
          40,
          40
        ],
        "1": [
          64,
          40,
          40,
          40
        ]
      },
      "ads": 296,
      "movement": 4600,
      "reload": 2370,
      "tstf": 392,
      "hipfireArea": 16.5,
      "movementAds": 2360,
      "bulletVelocity": 720,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 475,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "Bruen Mk9",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        26,
        56
      ],
      "damage": {
        "0": [
          46,
          31,
          31,
          31
        ],
        "1": [
          42,
          28,
          28,
          28
        ],
        "2": [
          33,
          22,
          22,
          22
        ]
      },
      "ads": 446,
      "movement": 4470,
      "reload": 5270,
      "tstf": 454,
      "hipfireArea": 27,
      "movementAds": 1810,
      "bulletVelocity": 720,
      "magSize": 100,
      "stf": 325,
      "obd": 0.067,
      "rpm": 752,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "Holger-26",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        48,
        71
      ],
      "damage": {
        "0": [
          42,
          28,
          28,
          28
        ],
        "1": [
          34,
          23,
          23,
          23
        ],
        "2": [
          27,
          18,
          18,
          18
        ]
      },
      "ads": 354,
      "movement": 4585,
      "reload": 3440,
      "tstf": 392,
      "hipfireArea": 24.5,
      "movementAds": 1940,
      "bulletVelocity": 720,
      "magSize": 100,
      "stf": 263,
      "obd": 0,
      "rpm": 704,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "M91",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        29,
        44.5
      ],
      "damage": {
        "0": [
          48,
          35,
          32,
          32
        ],
        "1": [
          46,
          34,
          31,
          31
        ],
        "2": [
          42,
          30,
          28,
          28
        ]
      },
      "ads": 417,
      "movement": 4366,
      "reload": 6970,
      "tstf": 454,
      "hipfireArea": 31.5,
      "movementAds": 1820,
      "bulletVelocity": 700,
      "magSize": 100,
      "stf": 325,
      "obd": 0.067,
      "rpm": 666,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "MG34",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        33
      ],
      "damage": {
        "0": [
          46,
          34,
          31,
          31
        ],
        "1": [
          42,
          30,
          28,
          28
        ]
      },
      "ads": 467,
      "movement": 4097,
      "reload": 8750,
      "tstf": 454,
      "hipfireArea": 31.5,
      "movementAds": 1620,
      "bulletVelocity": 680,
      "magSize": 50,
      "stf": 325,
      "obd": 0.067,
      "rpm": 877,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "PKM",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        39
      ],
      "damage": {
        "0": [
          46,
          34,
          31,
          31
        ],
        "1": [
          42,
          30,
          28,
          28
        ]
      },
      "ads": 413,
      "movement": 4375,
      "reload": 8850,
      "tstf": 454,
      "hipfireArea": 31.5,
      "movementAds": 1770,
      "bulletVelocity": 720,
      "magSize": 100,
      "stf": 325,
      "obd": 0.067,
      "rpm": 750,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "SA87",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        48
      ],
      "damage": {
        "0": [
          51,
          40,
          37,
          34
        ],
        "1": [
          43,
          34,
          31,
          29
        ]
      },
      "ads": 413,
      "movement": 4534,
      "reload": 3350,
      "tstf": 392,
      "hipfireArea": 25,
      "movementAds": 1950,
      "bulletVelocity": 840,
      "magSize": 30,
      "stf": 263,
      "obd": 0,
      "rpm": 600,
      "xSway": 2.4,
      "ySway": 3.1,
      "period": 1.75
    }
  },
  {
    "name": "FiNN LMG",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        30,
        44.5
      ],
      "damage": {
        "0": [
          36,
          36,
          28,
          28
        ],
        "1": [
          28,
          28,
          22,
          22
        ],
        "2": [
          27,
          27,
          21,
          21
        ]
      },
      "ads": 346,
      "movement": 4610,
      "reload": 5830,
      "tstf": 454,
      "hipfireArea": 22.7,
      "movementAds": 2029,
      "bulletVelocity": 720,
      "magSize": 75,
      "stf": 325,
      "obd": 0.067,
      "rpm": 630,
      "xSway": 3.1,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "FiNN LMG Factory Adverse",
    "type": "LMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        23,
        40.8
      ],
      "damage": {
        "0": [
          28,
          28,
          22,
          22
        ],
        "1": [
          27,
          27,
          21,
          21
        ],
        "2": [
          22,
          22,
          17,
          17
        ]
      },
      "ads": 346,
      "movement": 4610,
      "reload": 5830,
      "tstf": 454,
      "hipfireArea": 22.7,
      "movementAds": 2029,
      "bulletVelocity": 487,
      "magSize": 75,
      "stf": 325,
      "obd": 0.067,
      "rpm": 996,
      "xSway": 3.1,
      "ySway": 2.2,
      "period": 1.75
    }
  },
  {
    "name": "AUG",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        12.5,
        21,
        31.3
      ],
      "damage": {
        "0": [
          55,
          34,
          34,
          30
        ],
        "1": [
          40,
          25,
          25,
          22
        ],
        "2": [
          32,
          20,
          20,
          18
        ],
        "3": [
          29,
          18,
          18,
          16
        ]
      },
      "ads": 238,
      "movement": 4823,
      "reload": 2210,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2470,
      "bulletVelocity": 537,
      "magSize": 25,
      "stf": 117,
      "obd": 0,
      "rpm": 738,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "AUG 5.56",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        12.5,
        40.8,
        62
      ],
      "damage": {
        "0": [
          49,
          41,
          38,
          38
        ],
        "1": [
          36,
          30,
          28,
          28
        ],
        "2": [
          29,
          25,
          23,
          23
        ],
        "3": [
          23,
          19,
          18,
          18
        ]
      },
      "ads": 238,
      "movement": 4823,
      "reload": 2210,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2470,
      "bulletVelocity": 640,
      "magSize": 30,
      "stf": 117,
      "obd": 0,
      "rpm": 663,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Fennec",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        11,
        20,
        25.5
      ],
      "damage": {
        "0": [
          40,
          25,
          25,
          22
        ],
        "1": [
          32,
          20,
          20,
          18
        ],
        "2": [
          29,
          18,
          18,
          16
        ],
        "3": [
          24,
          15,
          15,
          13
        ]
      },
      "ads": 208,
      "movement": 4833,
      "reload": 2360,
      "tstf": 217,
      "hipfireArea": 6,
      "movementAds": 3000,
      "bulletVelocity": 217,
      "magSize": 25,
      "stf": 117,
      "obd": 0,
      "rpm": 1085,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Fennec .45 Hollow Point 12-R Mags",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        22,
        40
      ],
      "damage": {
        "0": [
          64,
          44,
          40,
          32
        ],
        "1": [
          55,
          37,
          34,
          27
        ],
        "2": [
          40,
          27,
          25,
          20
        ]
      },
      "ads": 192,
      "movement": 4833,
      "reload": 2800,
      "tstf": 217,
      "hipfireArea": 6,
      "movementAds": 3000,
      "bulletVelocity": 183,
      "magSize": 12,
      "stf": 117,
      "obd": 0,
      "rpm": 600,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "ISO",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        16.5,
        22.5,
        35
      ],
      "damage": {
        "0": [
          36,
          25,
          25,
          22
        ],
        "1": [
          29,
          20,
          20,
          18
        ],
        "2": [
          27,
          19,
          19,
          17
        ],
        "3": [
          23,
          16,
          16,
          14
        ]
      },
      "ads": 196,
      "movement": 4860,
      "reload": 2230,
      "tstf": 217,
      "hipfireArea": 5.4,
      "movementAds": 3090,
      "bulletVelocity": 520,
      "magSize": 20,
      "stf": 117,
      "obd": 0,
      "rpm": 882,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "MP5",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        9.5,
        17.5,
        25.8
      ],
      "damage": {
        "0": [
          49,
          34,
          34,
          30
        ],
        "1": [
          36,
          25,
          25,
          22
        ],
        "2": [
          31,
          22,
          22,
          19
        ],
        "3": [
          27,
          19,
          19,
          17
        ]
      },
      "ads": 200,
      "movement": 4854,
      "reload": 2790,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2960,
      "bulletVelocity": 537,
      "magSize": 30,
      "stf": 117,
      "obd": 0,
      "rpm": 797,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "MP5 10mm",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        10.5,
        21.5,
        41
      ],
      "damage": {
        "0": [
          49,
          34,
          34,
          30
        ],
        "1": [
          36,
          25,
          25,
          22
        ],
        "2": [
          31,
          22,
          22,
          19
        ],
        "3": [
          27,
          19,
          19,
          17
        ]
      },
      "ads": 200,
      "movement": 4854,
      "reload": 2790,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2960,
      "bulletVelocity": 433,
      "magSize": 30,
      "stf": 117,
      "obd": 0,
      "rpm": 797,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "MP7",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        10.9,
        17.2,
        21.8
      ],
      "damage": {
        "0": [
          40,
          25,
          25,
          22
        ],
        "1": [
          32,
          20,
          20,
          18
        ],
        "2": [
          29,
          18,
          18,
          16
        ],
        "3": [
          25,
          16,
          16,
          14
        ]
      },
      "ads": 196,
      "movement": 4919,
      "reload": 1970,
      "tstf": 217,
      "hipfireArea": 5.5,
      "movementAds": 3250,
      "bulletVelocity": 537,
      "magSize": 40,
      "stf": 117,
      "obd": 0,
      "rpm": 950,
      "xSway": 1,
      "ySway": 1.1,
      "period": 1
    }
  },
  {
    "name": "P90",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        12.8,
        19.2,
        26.2
      ],
      "damage": {
        "0": [
          40,
          25,
          25,
          22
        ],
        "1": [
          32,
          20,
          20,
          18
        ],
        "2": [
          29,
          18,
          18,
          16
        ],
        "3": [
          24,
          15,
          15,
          13
        ]
      },
      "ads": 196,
      "movement": 4807,
      "reload": 2780,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2720,
      "bulletVelocity": 605,
      "magSize": 50,
      "stf": 117,
      "obd": 0,
      "rpm": 904,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "PP19 Bizon",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        14.8,
        25,
        36
      ],
      "damage": {
        "0": [
          55,
          34,
          34,
          30
        ],
        "1": [
          40,
          25,
          25,
          22
        ],
        "2": [
          32,
          20,
          20,
          18
        ],
        "3": [
          30,
          19,
          19,
          17
        ]
      },
      "ads": 225,
      "movement": 4807,
      "reload": 2110,
      "tstf": 217,
      "hipfireArea": 7.5,
      "movementAds": 2860,
      "bulletVelocity": 537,
      "magSize": 64,
      "stf": 117,
      "obd": 0,
      "rpm": 653,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Striker-45",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        22.4,
        40,
        50
      ],
      "damage": {
        "0": [
          55,
          34,
          34,
          30
        ],
        "1": [
          40,
          25,
          25,
          22
        ],
        "2": [
          35,
          22,
          22,
          19
        ],
        "3": [
          30,
          19,
          19,
          17
        ]
      },
      "ads": 192,
      "movement": 4837,
      "reload": 2540,
      "tstf": 217,
      "hipfireArea": 8,
      "movementAds": 2860,
      "bulletVelocity": 537,
      "magSize": 25,
      "stf": 117,
      "obd": 0,
      "rpm": 605,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Striker-45 .45 ACP Burst",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        14.5,
        24,
        34.5
      ],
      "damage": {
        "0": [
          77,
          50,
          48,
          43
        ],
        "1": [
          61,
          39,
          38,
          34
        ],
        "2": [
          55,
          35,
          34,
          30
        ],
        "3": [
          38,
          25,
          25,
          21
        ]
      },
      "ads": 180,
      "movement": 4837,
      "reload": 2540,
      "tstf": 217,
      "hipfireArea": 8,
      "movementAds": 2860,
      "bulletVelocity": 406,
      "magSize": 12,
      "stf": 117,
      "obd": 0,
      "rpm": 400,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Uzi",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        15,
        23.8,
        36.6
      ],
      "damage": {
        "0": [
          55,
          34,
          34,
          30
        ],
        "1": [
          40,
          25,
          25,
          22
        ],
        "2": [
          35,
          22,
          22,
          19
        ],
        "3": [
          30,
          19,
          19,
          17
        ]
      },
      "ads": 163,
      "movement": 4961,
      "reload": 2300,
      "tstf": 254,
      "hipfireArea": 5.5,
      "movementAds": 3270,
      "bulletVelocity": 537,
      "magSize": 32,
      "stf": 163,
      "obd": 0.067,
      "rpm": 602,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "Uzi 0.41AE",
    "type": "SMG",
    "baseStats": {
      "damageDropoffs": [
        0,
        17.5,
        30,
        36,
        44
      ],
      "damage": {
        "0": [
          55,
          40,
          34,
          34
        ],
        "1": [
          46,
          34,
          29,
          29
        ],
        "2": [
          40,
          30,
          25,
          25
        ],
        "3": [
          35,
          26,
          22,
          22
        ],
        "4": [
          27,
          20,
          17,
          17
        ]
      },
      "ads": 163,
      "movement": 4961,
      "reload": 2300,
      "tstf": 254,
      "hipfireArea": 5.5,
      "movementAds": 3270,
      "bulletVelocity": 566,
      "magSize": 32,
      "stf": 163,
      "obd": 0.067,
      "rpm": 602,
      "xSway": 1,
      "ySway": 1.25,
      "period": 1
    }
  },
  {
    "name": "AS VAL",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        32
      ],
      "damage": {
        "0": [
          47,
          37,
          30,
          30
        ],
        "1": [
          36,
          28,
          23,
          23
        ]
      },
      "ads": 225,
      "movement": 4690,
      "reload": 2180,
      "tstf": 392,
      "hipfireArea": 15.3,
      "movementAds": 2670,
      "bulletVelocity": 333,
      "magSize": 20,
      "stf": 263,
      "obd": 0,
      "rpm": 885,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1
    }
  },
  {
    "name": "AS VAL (SPP 10-R Mags)",
    "type": "AR",
    "baseStats": {
      "damageDropoffs": [
        0,
        27.2,
        48.2
      ],
      "damage": {
        "0": [
          100,
          57,
          52,
          48
        ],
        "1": [
          94,
          54,
          49,
          45
        ],
        "2": [
          73,
          42,
          38,
          35
        ]
      },
      "ads": 204,
      "movement": 4830,
      "reload": 2180,
      "tstf": 392,
      "hipfireArea": 15.3,
      "movementAds": 3050,
      "bulletVelocity": 333,
      "magSize": 20,
      "stf": 263,
      "obd": 0,
      "rpm": 450,
      "xSway": 2.2,
      "ySway": 2.2,
      "period": 1
    }
  }
]