import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import Home from './Home';
import Share from './Share';

function App() {
  return (
    <Router>
      <Switch>
        <Route path={`/s/:shareId`}>
          <Share />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}

function Topic() {
  let { shareId } = useParams();
  return <h3>Requested share ID: {shareId}</h3>;
}


export default App;
