import React, { useContext } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import GlobalContext from './GlobalContext';

function HpSettings(props) {
  const { setHp, hp } = useContext(GlobalContext);

  const hpSettings = [
    { name: '100 HP', value: '100' },
    { name: '250 HP', value: '250' },
  ];

  return (
    <ButtonGroup toggle className="setting-buttons">
      {hpSettings.map((radio, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="outline-secondary"
          name="radio"
          value={radio.value}
          checked={hp === radio.value}
          onChange={(e) => setHp(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>

  );
}

export default HpSettings;