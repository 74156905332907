import React, { useRef } from 'react';
import { convertBpListToEntireUrlStr } from './urlToNumber';
import { InputGroup, FormControl, Button } from 'react-bootstrap';


function ShareUrlBar(props) {
  let { bpList } = props;
  bpList = bpList.filter(x => !x.deleted);
  if (bpList.length === 0) return (<></>);
  const shareUrl = 'codguncharts.com/s/' + convertBpListToEntireUrlStr(bpList);
  const fullShareUrl = 'https://' + shareUrl;

  return (
    <div id="share-url-bar">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Share URL"
          aria-label="Share URL"
          aria-describedby="share-url"
          rows={3}
          readOnly
          size="sm"
          as="textArea"
        >
          {shareUrl}
        </FormControl>
        <InputGroup.Append >
          <Button variant="outline-secondary" size="sm" onClick={() => { navigator.clipboard.writeText(fullShareUrl) }}>Copy</Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  )
}

export default ShareUrlBar;