import React from 'react';
import { useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import GlobalContext from './GlobalContext';
import { convertToOldBp } from './dataUtils';

function generateStkDataRow(bp, partOfBody = 'chest', hp) {
  let row = {
    id: null,
    data: []
  }

  let index = 1;
  if (partOfBody === 'head') index = 0;
  if (partOfBody === 'chest') index = 1;
  if (partOfBody === 'stomach') index = 2;
  if (partOfBody === 'legs') index = 3;

  const attachments = JSON.parse(JSON.stringify(bp));
  const baseGun = attachments.shift();
  const baseStats = baseGun.baseStats;

  const reducer = (acc, cur) => {
    return acc * (cur.statMods.range || 1);
  };
  const rangeMod = attachments.reduce(reducer, 1);

  row.id = baseGun.bpName;

  for (var i = 0; i < baseStats.damageDropoffs.length; i++) {
    row.data.push({
      "x": Math.round(baseStats.damageDropoffs[i] * rangeMod * 2) / 2,
      "y": damageHpToStk(baseStats.damage[i][index], hp)
    });
  }

  return row;
}

function generateStkData(bpList, partOfBody, hp) {
  let result = [];
  bpList
    .filter(x => x[0].deleted === false)
    .forEach(x => result.push(generateStkDataRow(x, partOfBody, hp)));

  return result;
}

function damageHpToStk(damage, hp = 250) {
  return Math.ceil(hp / damage);
}

const defaultLegends = [
  {
    anchor: 'bottom-right',
    direction: 'column',
    justify: false,
    translateX: 100,
    translateY: 0,
    itemsSpacing: 0,
    itemDirection: 'left-to-right',
    itemWidth: 80,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 12,
    symbolShape: 'circle',
    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [
      {
        on: 'hover',
        style: {
          itemBackground: 'rgba(0, 0, 0, .03)',
          itemOpacity: 1
        }
      }
    ]
  }
];

const alternativeLegends = [
  {
    anchor: 'top',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: -40,
    itemsSpacing: 5,
    itemDirection: 'top-to-bottom',
    itemWidth: 80,
    itemHeight: 40,
    itemOpacity: 0.75,
    symbolSize: 16,
    symbolShape: 'circle',
    symbolBorderColor: 'rgba(0, 0, 0, .5)',
    effects: [
      {
        on: 'hover',
        style: {
          itemBackground: 'rgba(0, 0, 0, .03)',
          itemOpacity: 1
        }
      }
    ]
  }
];

const MyResponsiveLine = ({ data, xMin, xMax, yMin, yMax }) => {
  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
        xScale={{ type: 'linear', min: xMin || 0, max: xMax || 70 }}
        yScale={{ type: 'linear', min: yMin || 0, max: yMax || 20, stacked: false, reverse: false }}
        curve="stepAfter"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: 'Distance (m)',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Shots/Bullets to Kill',
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(x => x * 2)
        }}
        colors={{ scheme: 'paired' }}
        pointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={3}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableSlices={false}
        debugSlices={false}
        useMesh={true}
        legends={defaultLegends}
        motionStiffness={300}
        motionDamping={40}
      />
    </>
  )
}

function StkChart() {
  let { bpList, hp, partOfBody } = useContext(GlobalContext);
  bpList = bpList.map(bp => convertToOldBp(bp));
  const data = generateStkData(bpList, partOfBody, parseInt(hp));

  let xMax = 50;
  let yMax = 5;
  let xMaxList = [];
  let yMaxList = [];
  let yMinList = [];

  data.forEach(points => {
    const len = points.data.length;
    const max = points.data[len - 1];
    const min = points.data[0];
    xMaxList.push(max.x);
    yMaxList.push(max.y);
    yMinList.push(min.y);
  });

  xMax = Math.max(...xMaxList);
  yMax = Math.max(...yMaxList);
  let yMin = Math.min(...yMinList)
  console.log(yMaxList, yMax)
  xMax += 20;
  yMax += 1;
  yMin -= 1;

  data.forEach(points => {
    const len = points.data.length;
    const p = points.data[len - 1];
    points.data.push({
      x: xMax,
      y: p.y
    })
  });

  return (
    MyResponsiveLine({ data, xMax, yMax, yMin })
  );
}

export default StkChart;