import React from 'react';
import { useContext } from 'react';
import GunListItem from './GunListItem';
import GlobalContext from './GlobalContext';

function GunList(props) {
  const { bpList, updateBpList } = useContext(GlobalContext);
  const { closeButton } = props;

  const renderList = () => {
    return (
      bpList
        .filter(x => x.deleted === false)
        .map((x) => (<GunListItem closeButton={closeButton} item={x} id={x.dateAdded} key={x.dateAdded}></GunListItem>))
    );
  };

  return (
    <div id="gun-list">
      {renderList()}
    </div>
  )
}

export default GunList;