export default {
  "AK-47": {
    "barrel": [
      {
        "name": "Spetsnaz Elite",
        "type": [
          "barrel"
        ],
        "id": 1,
        "statMods": {
          "range": 1.2,
          "ads": 17,
          "bulletVelocity": 1.12
        }
      },
      {
        "name": "23.0 RPK Barrel",
        "type": [
          "barrel"
        ],
        "id": 2,
        "statMods": {
          "range": 1.25,
          "ads": 50,
          "movement": 0.966,
          "bulletVelocity": 1.42,
          "vertRecoil": 0.91,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "8.1 Compact Barrel",
        "type": [
          "barrel"
        ],
        "id": 3,
        "statMods": {
          "range": 0.8,
          "ads": -50,
          "movement": 1.018,
          "bulletVelocity": 0.69,
          "vertRecoil": 1.14,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "23.0 Romanian",
        "type": [
          "barrel"
        ],
        "id": 4,
        "statMods": {
          "range": 1.32,
          "ads": 75,
          "movement": 0.97,
          "bulletVelocity": 1.55,
          "vertRecoil": 0.67,
          "hipfireArea": 1.67
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 5,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 6,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 7,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 27,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 28,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 8,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 9,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 10,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 11,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 12,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 13,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 14,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 15,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 16,
        "statMods": {
          "ads": 96
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 17,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 18,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 19,
        "statMods": {
          "ads": 83,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 20,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 21,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 22,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 23,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 24,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 25,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 26,
        "statMods": {
          "ads": 83
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 29,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 30,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 31,
        "statMods": {
          "ads": 25,
          "movement": 0.987,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 40,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 41,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "40 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 32,
        "statMods": {
          "ads": 4,
          "movement": 0.985,
          "magSize": 10
        }
      },
      {
        "name": "75 Round Drum Mags",
        "type": [
          "ammunition"
        ],
        "id": 33,
        "statMods": {
          "ads": 38,
          "movement": 0.907,
          "magSize": 45
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 34,
        "statMods": {
          "ads": -17,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 44,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 45,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 35,
        "statMods": {
          "ads": -25,
          "movementAds": 1.17,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 36,
        "statMods": {
          "ads": -46,
          "movement": 1.038,
          "movementAds": 1.2,
          "vertRecoil": 1.33,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 37,
        "statMods": {
          "ads": -29,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Field LMG Stock",
        "type": [
          "stock"
        ],
        "id": 46,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 47,
        "statMods": {
          "movementAds": 1.18,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 38,
        "statMods": {
          "ads": -37,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 42,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 43,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 39,
        "statMods": {
          "reload": -650
        }
      }
    ]
  },
  "AK-47 5.45x39mm": {
    "barrel": [
      {
        "name": "Spetsnaz Elite",
        "type": [
          "barrel"
        ],
        "id": 48,
        "statMods": {
          "range": 1.2,
          "ads": 17,
          "bulletVelocity": 1.12
        }
      },
      {
        "name": "23.0 RPK Barrel",
        "type": [
          "barrel"
        ],
        "id": 49,
        "statMods": {
          "range": 1.25,
          "ads": 50,
          "movement": 0.966,
          "bulletVelocity": 1.42,
          "vertRecoil": 0.91,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "8.1 Compact Barrel",
        "type": [
          "barrel"
        ],
        "id": 50,
        "statMods": {
          "range": 0.8,
          "ads": -50,
          "movement": 1.018,
          "bulletVelocity": 0.69,
          "vertRecoil": 1.14,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "23.0 Romanian",
        "type": [
          "barrel"
        ],
        "id": 51,
        "statMods": {
          "range": 1.32,
          "ads": 75,
          "movement": 0.97,
          "bulletVelocity": 1.55,
          "vertRecoil": 0.67,
          "hipfireArea": 1.67
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 52,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 53,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 54,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 74,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 75,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 55,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 56,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 57,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 58,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 59,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 60,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 61,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 62,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 63,
        "statMods": {
          "ads": 96
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 64,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 65,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 66,
        "statMods": {
          "ads": 83,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 67,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 68,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 69,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 70,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 71,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 72,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 73,
        "statMods": {
          "ads": 83
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 76,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 77,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 78,
        "statMods": {
          "ads": 25,
          "movement": 0.987,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 85,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 86,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 79,
        "statMods": {
          "ads": -17,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 89,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 90,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 80,
        "statMods": {
          "ads": -25,
          "movementAds": 1.17,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 81,
        "statMods": {
          "ads": -46,
          "movement": 1.038,
          "movementAds": 1.2,
          "vertRecoil": 1.33,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 82,
        "statMods": {
          "ads": -29,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Field LMG Stock",
        "type": [
          "stock"
        ],
        "id": 91,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 92,
        "statMods": {
          "movementAds": 1.18,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 83,
        "statMods": {
          "ads": -37,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 87,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 88,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 84,
        "statMods": {
          "reload": -650
        }
      }
    ]
  },
  "AN-94": {
    "optic": [
      {
        "name": "G.I. Mini Reflex",
        "type": [
          "optic"
        ],
        "id": 93,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 94,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 95,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 96,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "APX5 Holographic",
        "type": [
          "optic"
        ],
        "id": 97,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 98,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 99,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 100,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 101,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 102,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 103,
        "statMods": {
          "ads": 71,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 104,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 105,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 106,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 107,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 108,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 109,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 110,
        "statMods": {
          "ads": 100
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 111,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex",
        "type": [
          "optic"
        ],
        "id": 112,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 113,
        "statMods": {
          "ads": 84
        }
      },
      {
        "name": "POS-M3 Thermal Scope",
        "type": [
          "optic"
        ],
        "id": 114,
        "statMods": {
          "ads": 67
        }
      }
    ],
    "muzzle": [
      {
        "name": "AN-94 Sonic Brake",
        "type": [
          "muzzle"
        ],
        "id": 115,
        "statMods": {
          "ads": 13,
          "horizRecoil": 0.8
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 116,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 117,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 118,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 119,
        "statMods": {
          "range": 1.1,
          "ads": 34,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "AN-94 Factory 330mm",
        "type": [
          "barrel"
        ],
        "id": 120,
        "statMods": {
          "range": 0.85,
          "ads": -29,
          "movement": 1.015,
          "bulletVelocity": 0.71,
          "vertRecoil": 1.09,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "AN-94 Factory X-438mm",
        "type": [
          "barrel"
        ],
        "id": 121,
        "statMods": {
          "range": 1.34,
          "ads": 46,
          "movement": 0.97,
          "bulletVelocity": 1.51,
          "vertRecoil": 0.83,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "VLK AN-94 Sila",
        "type": [
          "barrel"
        ],
        "id": 122,
        "statMods": {
          "range": 1.193,
          "ads": 25,
          "bulletVelocity": 1.16
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 123,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 124,
        "statMods": {
          "ads": 34,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 125,
        "statMods": {
          "ads": 29,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 126,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 127,
        "statMods": {
          "ads": 38,
          "vertRecoil": 0.83
        }
      }
    ],
    "ammunition": [
      {
        "name": "45 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 128,
        "statMods": {
          "ads": 9,
          "movement": 0.987,
          "magSize": 15
        }
      },
      {
        "name": "60 Round Casket Mags",
        "type": [
          "ammunition"
        ],
        "id": 129,
        "statMods": {
          "ads": 9,
          "movement": 0.972,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 130,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      },
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 131,
        "statMods": {
          "ads": -12,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 132,
        "statMods": {
          "vertRecoil": 0.94,
          "xSway": 1.1,
          "ySway": 1.2
        }
      }
    ],
    "stock": [
      {
        "name": "AN-94 Factory Heavy",
        "type": [
          "stock"
        ],
        "id": 133,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 134,
        "statMods": {
          "movementAds": 1.17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Folded Stock",
        "type": [
          "stock"
        ],
        "id": 135,
        "statMods": {
          "ads": -33,
          "movement": 1.042,
          "movementAds": 1.2,
          "vertRecoil": 1.26,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 136,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "VLK PX-9 Pero",
        "type": [
          "stock"
        ],
        "id": 137,
        "statMods": {
          "ads": -8,
          "movementAds": 1.19,
          "xSway": 1.4,
          "ySway": 1.4
        }
      }
    ],
    "laser": [
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 138,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 139,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 140,
        "statMods": {
          "ads": -29,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 141,
        "statMods": {
          "reload": -600
        }
      }
    ]
  },
  "CR-56 AMAX": {
    "optic": [
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 142,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 143,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 144,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 145,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 146,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 147,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 148,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 149,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 150,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 151,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 152,
        "statMods": {
          "ads": 71,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 153,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 154,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 155,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 156,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 157,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 158,
        "statMods": {
          "ads": 109
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 159,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 160,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 161,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 162,
        "statMods": {
          "ads": 84
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 163,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 164,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 165,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 166,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 167,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "FSS 8.3 Intruder",
        "type": [
          "barrel"
        ],
        "id": 168,
        "statMods": {
          "range": 0.87,
          "ads": -29,
          "movement": 1.013,
          "bulletVelocity": 0.76,
          "vertRecoil": 1.08,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "XRK Zodiac S440",
        "type": [
          "barrel"
        ],
        "id": 169,
        "statMods": {
          "range": 1.32,
          "ads": 54,
          "movement": 0.968,
          "bulletVelocity": 1.42,
          "vertRecoil": 0.78,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "FSS 11.8 Squall",
        "type": [
          "barrel"
        ],
        "id": 170,
        "statMods": {
          "range": 0.97,
          "ads": -25,
          "bulletVelocity": 0.84
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 171,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 172,
        "statMods": {
          "ads": 17,
          "movement": 0.987,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 173,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 181,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 182,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "45 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 174,
        "statMods": {
          "ads": 9,
          "movement": 0.984,
          "magSize": 15
        }
      }
    ],
    "rear grip": [
      {
        "name": "XRK CR-56 Stippled Wrap",
        "type": [
          "rear grip"
        ],
        "id": 175,
        "statMods": {
          "ads": -12,
          "tstf": -71,
          "stf": -34
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 185,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 186,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 176,
        "statMods": {
          "ads": -33,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 177,
        "statMods": {
          "ads": -46,
          "movement": 1.044,
          "movementAds": 1.2,
          "vertRecoil": 1.32,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "CR-56 EXO",
        "type": [
          "stock"
        ],
        "id": 178,
        "statMods": {
          "ads": -25,
          "movementAds": 1.18,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FTAC Hunter",
        "type": [
          "stock"
        ],
        "id": 187,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FTAC Spartan",
        "type": [
          "stock"
        ],
        "id": 188,
        "statMods": {
          "movementAds": 1.15,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "XRK Gatekeeper",
        "type": [
          "stock"
        ],
        "id": 189,
        "statMods": {
          "movementAds": 0.85,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 179,
        "statMods": {
          "ads": -41,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 183,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 184,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 180,
        "statMods": {
          "reload": -710
        }
      }
    ]
  },
  "CR-56 AMAX M67 10-R Mags": {
    "optic": [
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 190,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 191,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 192,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 193,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 194,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 195,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 196,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 197,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 198,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 199,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 200,
        "statMods": {
          "ads": 71,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 201,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 202,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 203,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 204,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 205,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 206,
        "statMods": {
          "ads": 109
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 207,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 208,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 209,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 210,
        "statMods": {
          "ads": 84
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 211,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 212,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 213,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 214,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 215,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "FSS 8.3 Intruder",
        "type": [
          "barrel"
        ],
        "id": 216,
        "statMods": {
          "range": 0.87,
          "ads": -29,
          "movement": 1.013,
          "bulletVelocity": 0.76,
          "vertRecoil": 1.08,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "XRK Zodiac S440",
        "type": [
          "barrel"
        ],
        "id": 217,
        "statMods": {
          "range": 1.32,
          "ads": 54,
          "movement": 0.968,
          "bulletVelocity": 1.42,
          "vertRecoil": 0.78,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "FSS 11.8 Squall",
        "type": [
          "barrel"
        ],
        "id": 218,
        "statMods": {
          "range": 0.97,
          "ads": -25,
          "bulletVelocity": 0.84
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 219,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 220,
        "statMods": {
          "ads": 17,
          "movement": 0.987,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 221,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 228,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 229,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "XRK CR-56 Stippled Wrap",
        "type": [
          "rear grip"
        ],
        "id": 222,
        "statMods": {
          "ads": -12,
          "tstf": -71,
          "stf": -34
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 232,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 233,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 223,
        "statMods": {
          "ads": -33,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 224,
        "statMods": {
          "ads": -46,
          "movement": 1.044,
          "movementAds": 1.2,
          "vertRecoil": 1.32,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "CR-56 EXO",
        "type": [
          "stock"
        ],
        "id": 225,
        "statMods": {
          "ads": -25,
          "movementAds": 1.09,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FTAC Hunter",
        "type": [
          "stock"
        ],
        "id": 234,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FTAC Spartan",
        "type": [
          "stock"
        ],
        "id": 235,
        "statMods": {
          "movementAds": 1.15,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "XRK Gatekeeper",
        "type": [
          "stock"
        ],
        "id": 236,
        "statMods": {
          "movementAds": 0.85,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 226,
        "statMods": {
          "ads": -41,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 230,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 231,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 227,
        "statMods": {
          "reload": -710
        }
      }
    ]
  },
  "FAL": {
    "barrel": [
      {
        "name": "18.0 Ultralight",
        "type": [
          "barrel"
        ],
        "id": 237,
        "statMods": {
          "range": 0.8333,
          "ads": -17,
          "bulletVelocity": 0.86
        }
      },
      {
        "name": "XRK Marksman",
        "type": [
          "barrel"
        ],
        "id": 238,
        "statMods": {
          "range": 1.22,
          "ads": 42,
          "movement": 0.979,
          "bulletVelocity": 1.33,
          "vertRecoil": 0.85,
          "hipfireArea": 1.48
        }
      },
      {
        "name": "13.0 OSW Para",
        "type": [
          "barrel"
        ],
        "id": 239,
        "statMods": {
          "range": 0.89,
          "ads": -21,
          "movement": 1.012,
          "bulletVelocity": 0.86,
          "vertRecoil": 1.08,
          "hipfireArea": 0.95
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 240,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 241,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 242,
        "statMods": {
          "range": 1.1,
          "ads": 29,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 264,
        "statMods": {
          "ads": 13,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 265,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 243,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 244,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 245,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 246,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 247,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 248,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 249,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 250,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 251,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 252,
        "statMods": {
          "ads": 88
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 253,
        "statMods": {
          "ads": 67,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 254,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 255,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 256,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 257,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 258,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 259,
        "statMods": {
          "ads": 109
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 260,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 261,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 262,
        "statMods": {
          "ads": 63
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 263,
        "statMods": {
          "ads": 84
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 266,
        "statMods": {
          "ads": 27,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 267,
        "statMods": {
          "ads": 21,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 268,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 276,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 277,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "24 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 269,
        "statMods": {
          "ads": 9,
          "movement": 0.988,
          "magSize": 4
        }
      },
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 270,
        "statMods": {
          "ads": 13,
          "movement": 0.969,
          "magSize": 10
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 271,
        "statMods": {
          "ads": -8,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 280,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 281,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 272,
        "statMods": {
          "ads": -37,
          "movement": 1.037,
          "movementAds": 1.2,
          "vertRecoil": 1.3,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 273,
        "statMods": {
          "ads": -12,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Factory 18 Aluminum Stock",
        "type": [
          "stock"
        ],
        "id": 282,
        "statMods": {
          "movementAds": 1.15,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 283,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 274,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 278,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 279,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 275,
        "statMods": {
          "reload": -600
        }
      }
    ]
  },
  "FN Scar 17": {
    "barrel": [
      {
        "name": "Forge Tac 17.2 LB",
        "type": [
          "barrel"
        ],
        "id": 284,
        "statMods": {
          "range": 1.23,
          "ads": 17,
          "movement": 0.975,
          "bulletVelocity": 1.47,
          "vertRecoil": 0.92,
          "hipfireArea": 1.48
        }
      },
      {
        "name": "Forge Tac 20.0 LB",
        "type": [
          "barrel"
        ],
        "id": 285,
        "statMods": {
          "range": 1.38,
          "ads": 42,
          "movement": 0.958,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.9,
          "hipfireArea": 1.82
        }
      },
      {
        "name": "Forge Tac CQC Pro",
        "type": [
          "barrel"
        ],
        "id": 286,
        "statMods": {
          "range": 0.83,
          "ads": -58,
          "movement": 1.018,
          "bulletVelocity": 0.7,
          "vertRecoil": 1.44,
          "hipfireArea": 0.92
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 287,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 288,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 289,
        "statMods": {
          "range": 1.1,
          "ads": 29,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 311,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 312,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 290,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 291,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 292,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 293,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 294,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 295,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 296,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 297,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 298,
        "statMods": {
          "ads": 96
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 299,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 300,
        "statMods": {
          "ads": 100
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 301,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 302,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 303,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 304,
        "statMods": {
          "ads": 71,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 305,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 306,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 307,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 308,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 309,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 310,
        "statMods": {
          "ads": 54
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 313,
        "statMods": {
          "ads": 33,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Bipod Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 314,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 315,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 323,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 324,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "25 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 316,
        "statMods": {
          "ads": 4,
          "movement": 0.985,
          "magSize": 5
        }
      },
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 317,
        "statMods": {
          "ads": 4,
          "movement": 0.966,
          "magSize": 10
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 318,
        "statMods": {
          "ads": -8,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 327,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 328,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FTAC Collapsible Stock",
        "type": [
          "stock"
        ],
        "id": 319,
        "statMods": {
          "ads": -38,
          "movement": 1.038,
          "movementAds": 1.2,
          "vertRecoil": 1.45,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 320,
        "statMods": {
          "ads": -29,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Hunter",
        "type": [
          "stock"
        ],
        "id": 329,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "XRK Obelisk Pro",
        "type": [
          "stock"
        ],
        "id": 330,
        "statMods": {
          "movementAds": 0.84,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 321,
        "statMods": {
          "ads": -38,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 325,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 326,
        "statMods": {
          "hipfireArea": 0.64
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 322,
        "statMods": {
          "reload": -770
        }
      }
    ]
  },
  "FR 5.56": {
    "barrel": [
      {
        "name": "FR 15.9 Commando",
        "type": [
          "barrel"
        ],
        "id": 331,
        "statMods": {
          "range": 0.85,
          "ads": -21,
          "movement": 1.005,
          "bulletVelocity": 0.9,
          "vertRecoil": 1.1,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "FR 24.4 Sniper",
        "type": [
          "barrel"
        ],
        "id": 332,
        "statMods": {
          "range": 1.3,
          "ads": 58,
          "movement": 0.967,
          "bulletVelocity": 1.47,
          "vertRecoil": 0.8,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "barrel"
        ],
        "id": 333,
        "statMods": {
          "range": 0.87,
          "ads": -16,
          "bulletVelocity": 0.8
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 334,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 335,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 336,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 358,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 359,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 337,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 338,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 339,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 340,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 341,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 342,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 343,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 344,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 345,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 346,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 347,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 348,
        "statMods": {
          "ads": 75,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 349,
        "statMods": {
          "ads": 104
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 350,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 351,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 352,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 353,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 354,
        "statMods": {
          "ads": 121
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 355,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 356,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 357,
        "statMods": {
          "ads": 54
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 360,
        "statMods": {
          "ads": 33,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 361,
        "statMods": {
          "ads": 33,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 362,
        "statMods": {
          "ads": 29,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 369,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 370,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mag",
        "type": [
          "ammunition"
        ],
        "id": 363,
        "statMods": {
          "ads": 8,
          "movement": 0.981,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mag",
        "type": [
          "ammunition"
        ],
        "id": 364,
        "statMods": {
          "ads": 8,
          "movement": 0.969,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 365,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 373,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 374,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Tac-Wrap",
        "type": [
          "stock"
        ],
        "id": 366,
        "statMods": {
          "ads": -29,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FR Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 375,
        "statMods": {
          "movementAds": 1.15,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Ballast Pack",
        "type": [
          "stock"
        ],
        "id": 376,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 367,
        "statMods": {
          "ads": -42,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 371,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 372,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 368,
        "statMods": {
          "reload": -590
        }
      }
    ]
  },
  "Grau 5.56": {
    "barrel": [
      {
        "name": "ZLR Drifter A08",
        "type": [
          "barrel",
          "underbarrel"
        ],
        "id": 377,
        "statMods": {
          "range": 0.8,
          "ads": -38,
          "movement": 1.02,
          "bulletVelocity": 0.7,
          "vertRecoil": 0.88,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "Tempus 26.4 Archangel",
        "type": [
          "barrel"
        ],
        "id": 378,
        "statMods": {
          "range": 1.4,
          "ads": 71,
          "movement": 0.96,
          "bulletVelocity": 1.57,
          "vertRecoil": 0.79,
          "hipfireArea": 1.8
        }
      },
      {
        "name": "XRK CZEN mk2",
        "type": [
          "barrel"
        ],
        "id": 379,
        "statMods": {
          "range": 1.25,
          "ads": 12,
          "bulletVelocity": 1.16
        }
      },
      {
        "name": "FFS 20.8 Nexus",
        "type": [
          "barrel"
        ],
        "id": 380,
        "statMods": {
          "range": 1.35,
          "ads": 33,
          "movement": 0.971,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.83,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "FSS 11.8 Squall",
        "type": [
          "barrel"
        ],
        "id": 381,
        "statMods": {
          "range": 0.88,
          "ads": -16,
          "bulletVelocity": 0.88
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 382,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 383,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 384,
        "statMods": {
          "range": 1.1,
          "ads": 17,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 405,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 406,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 385,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 386,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 387,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 388,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 389,
        "statMods": {
          "ads": 48
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 390,
        "statMods": {
          "ads": 6
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 391,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 392,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 393,
        "statMods": {
          "ads": 54,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 394,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 395,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 396,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 397,
        "statMods": {
          "ads": 6
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 398,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 399,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 400,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 401,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 402,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 403,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 404,
        "statMods": {
          "ads": 58
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 407,
        "statMods": {
          "ads": 17,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 408,
        "statMods": {
          "ads": 17,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 409,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 416,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Cronen Shark II",
        "type": [
          "underbarrel"
        ],
        "id": 417,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 410,
        "statMods": {
          "ads": 4,
          "movement": 0.983,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 411,
        "statMods": {
          "ads": 4,
          "movement": 0.97,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "XRK Void II",
        "type": [
          "rear grip"
        ],
        "id": 412,
        "statMods": {
          "ads": -17,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Cronen Sniper Elite",
        "type": [
          "rear grip"
        ],
        "id": 420,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "FTAC Rubber",
        "type": [
          "rear grip"
        ],
        "id": 421,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 413,
        "statMods": {
          "ads": -38,
          "movement": 1.039,
          "movementAds": 1.2,
          "vertRecoil": 1.25,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Blackjack",
        "type": [
          "stock"
        ],
        "id": 422,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "XRK StrikeLite III",
        "type": [
          "stock"
        ],
        "id": 423,
        "statMods": {
          "movementAds": 1.13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 414,
        "statMods": {
          "ads": -33,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 418,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 419,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 415,
        "statMods": {
          "reload": -550
        }
      }
    ]
  },
  "Kilo 141": {
    "barrel": [
      {
        "name": "Singuard Arms 16.6 SOCOM",
        "type": [
          "barrel"
        ],
        "id": 424,
        "statMods": {
          "range": 1.28,
          "ads": 45,
          "bulletVelocity": 1.28,
          "vertRecoil": 0.82,
          "hipfireArea": 1.52
        }
      },
      {
        "name": "Singuard Arms 19.8 Prowler",
        "type": [
          "barrel"
        ],
        "id": 425,
        "statMods": {
          "range": 1.45,
          "ads": 62,
          "movement": 0.959,
          "bulletVelocity": 1.57,
          "vertRecoil": 0.68,
          "hipfireArea": 1.8
        }
      },
      {
        "name": "Singuard Arms Whisper",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 426,
        "statMods": {
          "range": 1.15,
          "ads": 29,
          "bulletVelocity": 1.29
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 427,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 428,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 429,
        "statMods": {
          "range": 1.1,
          "ads": 16,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 451,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 452,
        "statMods": {
          "ads": 8,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 430,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 431,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 432,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 433,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 434,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 435,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 436,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 437,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 438,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 439,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 440,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 441,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 442,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 443,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 444,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 445,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 446,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 447,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 448,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 449,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 450,
        "statMods": {
          "ads": 95
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 453,
        "statMods": {
          "ads": 25,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 454,
        "statMods": {
          "ads": 33,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 455,
        "statMods": {
          "ads": 20,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 464,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 465,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 456,
        "statMods": {
          "ads": 12,
          "movement": 0.983,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 457,
        "statMods": {
          "ads": 12,
          "movement": 0.968,
          "magSize": 30
        }
      },
      {
        "name": "100 Round Drums",
        "type": [
          "ammunition"
        ],
        "id": 458,
        "statMods": {
          "ads": 45,
          "movement": 0.914,
          "magSize": 70
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 459,
        "statMods": {
          "ads": -9,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 468,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 469,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 460,
        "statMods": {
          "ads": -42,
          "movement": 1.038,
          "movementAds": 1.2,
          "vertRecoil": 1.32,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 461,
        "statMods": {
          "ads": -17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 470,
        "statMods": {
          "movementAds": 1.13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Singuard Arms Sniper Pro",
        "type": [
          "stock"
        ],
        "id": 471,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 462,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 466,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 467,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 463,
        "statMods": {
          "reload": -960
        }
      }
    ]
  },
  "M13": {
    "barrel": [
      {
        "name": "Tempus Mini",
        "type": [
          "barrel"
        ],
        "id": 472,
        "statMods": {
          "range": 0.733,
          "ads": -42,
          "movement": 1.017,
          "bulletVelocity": 0.72,
          "vertRecoil": 1.1,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "Tempus Cyclone",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 473,
        "statMods": {
          "range": 1.15,
          "ads": 25,
          "bulletVelocity": 1.16
        }
      },
      {
        "name": "Tempus Marksman",
        "type": [
          "barrel"
        ],
        "id": 474,
        "statMods": {
          "range": 1.333,
          "ads": 41,
          "movement": 0.967,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.8,
          "hipfireArea": 1.68
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 475,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 476,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 477,
        "statMods": {
          "range": 1.1,
          "ads": 20,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 499,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 500,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 478,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 479,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 480,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 481,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 482,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 483,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 484,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 485,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 486,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 487,
        "statMods": {
          "ads": 20,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 488,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 489,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 490,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 491,
        "statMods": {
          "ads": 62,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 492,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 493,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 494,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 495,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 496,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 497,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 498,
        "statMods": {
          "ads": 100
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 501,
        "statMods": {
          "ads": 20,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 502,
        "statMods": {
          "ads": 20,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 503,
        "statMods": {
          "ads": 20,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 512,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 513,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 504,
        "statMods": {
          "ads": 4,
          "movement": 0.983,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 505,
        "statMods": {
          "ads": 4,
          "movement": 0.967,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 506,
        "statMods": {
          "ads": -9,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 516,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 517,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 507,
        "statMods": {
          "ads": -34,
          "movement": 1.036,
          "movementAds": 1.2,
          "vertRecoil": 1.25,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 508,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "M13 Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 509,
        "statMods": {
          "ads": -13,
          "movementAds": 1.15,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 518,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 510,
        "statMods": {
          "ads": -30,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 514,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 515,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 511,
        "statMods": {
          "reload": -970
        }
      }
    ]
  },
  "M13 .300 Blackout": {
    "barrel": [
      {
        "name": "Tempus Mini",
        "type": [
          "barrel"
        ],
        "id": 519,
        "statMods": {
          "range": 0.733,
          "ads": -42,
          "movement": 1.017,
          "bulletVelocity": 0.72,
          "vertRecoil": 1.1,
          "hipfireArea": 0.94
        }
      },
      {
        "name": "Tempus Cyclone",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 520,
        "statMods": {
          "range": 1.15,
          "ads": 25,
          "bulletVelocity": 1.16
        }
      },
      {
        "name": "Tempus Marksman",
        "type": [
          "barrel"
        ],
        "id": 521,
        "statMods": {
          "range": 1.333,
          "ads": 41,
          "movement": 0.967,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.8,
          "hipfireArea": 1.68
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 522,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 523,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 524,
        "statMods": {
          "range": 1.1,
          "ads": 20,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 546,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 547,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 525,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 526,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 527,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 528,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 529,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 530,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 531,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 532,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 533,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 534,
        "statMods": {
          "ads": 20,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 535,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 536,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 537,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 538,
        "statMods": {
          "ads": 62,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 539,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 540,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 541,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 542,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 543,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 544,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 545,
        "statMods": {
          "ads": 100
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 548,
        "statMods": {
          "ads": 20,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 549,
        "statMods": {
          "ads": 20,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 550,
        "statMods": {
          "ads": 20,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 557,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 558,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 551,
        "statMods": {
          "ads": -9,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 561,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 562,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 552,
        "statMods": {
          "ads": -34,
          "movement": 1.036,
          "movementAds": 1.2,
          "vertRecoil": 1.25,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 553,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "M13 Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 554,
        "statMods": {
          "ads": -13,
          "movementAds": 1.15,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 563,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 555,
        "statMods": {
          "ads": -30,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 559,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 560,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 556,
        "statMods": {
          "reload": -970
        }
      }
    ]
  },
  "M4A1": {
    "barrel": [
      {
        "name": "FSS 11.5 Commando",
        "type": [
          "barrel"
        ],
        "id": 564,
        "statMods": {
          "range": 0.75,
          "ads": -33,
          "movement": 1.014,
          "bulletVelocity": 0.71,
          "vertRecoil": 1.19,
          "hipfireArea": 0.92
        }
      },
      {
        "name": "Stock M16 Grenadier",
        "type": [
          "barrel"
        ],
        "id": 565,
        "statMods": {
          "range": 1.4,
          "ads": 67,
          "movement": 0.956,
          "bulletVelocity": 1.53,
          "vertRecoil": 0.78,
          "hipfireArea": 1.8
        }
      },
      {
        "name": "FSS 14.5 Tac Lite",
        "type": [
          "barrel"
        ],
        "id": 566,
        "statMods": {
          "range": 0.84,
          "ads": -8,
          "bulletVelocity": 0.85
        }
      },
      {
        "name": "Corvus Custom Marksman",
        "type": [
          "barrel"
        ],
        "id": 567,
        "statMods": {
          "range": 1.3,
          "ads": 46,
          "movement": 0.979,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.84,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "FSS 12.4 Predator",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 568,
        "statMods": {
          "range": 0.66,
          "ads": -8,
          "bulletVelocity": 0.82
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 569,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 570,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 571,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 592,
        "statMods": {
          "ads": 16,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 593,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 572,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 573,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 574,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 575,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 576,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 577,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 578,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 579,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 580,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 581,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 582,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 583,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 584,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 585,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 586,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 587,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 588,
        "statMods": {
          "ads": 95
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 589,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 590,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 591,
        "statMods": {
          "ads": 75
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 594,
        "statMods": {
          "ads": 12,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 595,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 596,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 604,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 605,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 597,
        "statMods": {
          "ads": 4,
          "movement": 0.98,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 598,
        "statMods": {
          "ads": 6,
          "movement": 0.97,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 599,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 608,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 609,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 600,
        "statMods": {
          "ads": -38,
          "movement": 1.034,
          "movementAds": 1.2,
          "vertRecoil": 1.27,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC CQS",
        "type": [
          "stock"
        ],
        "id": 601,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "M-16 Stock",
        "type": [
          "stock"
        ],
        "id": 610,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "Singuard Arms Invader",
        "type": [
          "stock"
        ],
        "id": 611,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 602,
        "statMods": {
          "ads": -38,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 606,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 607,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 603,
        "statMods": {
          "reload": -820
        }
      }
    ]
  },
  "M4A1 .458 SOCOM": {
    "barrel": [
      {
        "name": "FSS 11.5 Commando",
        "type": [
          "barrel"
        ],
        "id": 612,
        "statMods": {
          "range": 0.75,
          "ads": -33,
          "movement": 1.014,
          "bulletVelocity": 0.71,
          "vertRecoil": 1.19,
          "hipfireArea": 0.92
        }
      },
      {
        "name": "Stock M16 Grenadier",
        "type": [
          "barrel"
        ],
        "id": 613,
        "statMods": {
          "range": 1.4,
          "ads": 67,
          "movement": 0.956,
          "bulletVelocity": 1.53,
          "vertRecoil": 0.78,
          "hipfireArea": 1.8
        }
      },
      {
        "name": "FSS 14.5 Tac Lite",
        "type": [
          "barrel"
        ],
        "id": 614,
        "statMods": {
          "range": 0.84,
          "ads": -8,
          "bulletVelocity": 0.85
        }
      },
      {
        "name": "Corvus Custom Marksman",
        "type": [
          "barrel"
        ],
        "id": 615,
        "statMods": {
          "range": 1.3,
          "ads": 46,
          "movement": 0.979,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.84,
          "hipfireArea": 1.68
        }
      },
      {
        "name": "FSS 12.4 Predator",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 616,
        "statMods": {
          "range": 0.66,
          "ads": -8,
          "bulletVelocity": 0.82
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 617,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 618,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 619,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 640,
        "statMods": {
          "ads": 16,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 641,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 620,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 621,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 622,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 623,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 624,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 625,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 626,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 627,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 628,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 629,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 630,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 631,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 632,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 633,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 634,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 635,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 636,
        "statMods": {
          "ads": 95
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 637,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 638,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 639,
        "statMods": {
          "ads": 75
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 642,
        "statMods": {
          "ads": 12,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 643,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 644,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 649,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 650,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 645,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 653,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 654,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 646,
        "statMods": {
          "ads": -38,
          "movement": 1.034,
          "movementAds": 1.2,
          "vertRecoil": 1.27,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC CQS",
        "type": [
          "stock"
        ],
        "id": 647,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "M-16 Stock",
        "type": [
          "stock"
        ],
        "id": 655,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "Singuard Arms Invader",
        "type": [
          "stock"
        ],
        "id": 656,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 648,
        "statMods": {
          "ads": -38,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 651,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 652,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 657,
        "statMods": {
          "reload": -820
        }
      }
    ]
  },
  "M4A1 9mm": {
    "barrel": [
      {
        "name": "FSS 11.5 Commando",
        "type": [
          "barrel"
        ],
        "id": 658,
        "statMods": {
          "range": 0.75,
          "ads": -33,
          "movement": 1.014,
          "bulletVelocity": 0.71,
          "vertRecoil": 1.19,
          "hipfireArea": 0.91
        }
      },
      {
        "name": "Stock M16 Grenadier",
        "type": [
          "barrel"
        ],
        "id": 659,
        "statMods": {
          "range": 1.4,
          "ads": 67,
          "movement": 0.956,
          "bulletVelocity": 1.53,
          "vertRecoil": 0.78,
          "hipfireArea": 1.79
        }
      },
      {
        "name": "FSS 14.5 Tac Lite",
        "type": [
          "barrel"
        ],
        "id": 660,
        "statMods": {
          "range": 0.84,
          "ads": -8,
          "bulletVelocity": 0.85
        }
      },
      {
        "name": "Corvus Custom Marksman",
        "type": [
          "barrel"
        ],
        "id": 661,
        "statMods": {
          "range": 1.3,
          "ads": 46,
          "movement": 0.979,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.84,
          "hipfireArea": 1.71
        }
      },
      {
        "name": "FSS 12.4 Predator",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 662,
        "statMods": {
          "range": 0.66,
          "ads": -8,
          "bulletVelocity": 0.82
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 663,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 664,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 665,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 686,
        "statMods": {
          "ads": 16,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 687,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 666,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 667,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 668,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 669,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 670,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 671,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 672,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 673,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 674,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 675,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 676,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 677,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 678,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 679,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 680,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 681,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 682,
        "statMods": {
          "ads": 95
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 683,
        "statMods": {
          "ads": 45
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 684,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 685,
        "statMods": {
          "ads": 75
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 688,
        "statMods": {
          "ads": 12,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 689,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 690,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 695,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 696,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 691,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 699,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 700,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 692,
        "statMods": {
          "ads": -38,
          "movement": 1.034,
          "movementAds": 1.2,
          "vertRecoil": 1.27,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC CQS",
        "type": [
          "stock"
        ],
        "id": 693,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "M-16 Stock",
        "type": [
          "stock"
        ],
        "id": 701,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "Singuard Arms Invader",
        "type": [
          "stock"
        ],
        "id": 702,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 694,
        "statMods": {
          "ads": -38,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 697,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 698,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ]
  },
  "Oden": {
    "barrel": [
      {
        "name": "Oden Factory 810mm",
        "type": [
          "barrel"
        ],
        "id": 703,
        "statMods": {
          "range": 1.35,
          "ads": 46,
          "movement": 0.972,
          "bulletVelocity": 1.52,
          "vertRecoil": 0.74,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "Oden Factory 730mm",
        "type": [
          "barrel"
        ],
        "id": 704,
        "statMods": {
          "range": 1.235,
          "ads": 17,
          "movement": 0.993,
          "bulletVelocity": 1.22,
          "vertRecoil": 0.85,
          "hipfireArea": 1.31
        }
      },
      {
        "name": "Oden Factory 420mm",
        "type": [
          "barrel"
        ],
        "id": 705,
        "statMods": {
          "range": 0.9,
          "ads": -33,
          "movement": 1.014,
          "bulletVelocity": 0.81,
          "vertRecoil": 1.03,
          "hipfireArea": 0.93
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 706,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 707,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 708,
        "statMods": {
          "range": 1.1,
          "ads": 33,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Colossus Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 709,
        "statMods": {
          "range": 1.45,
          "ads": 54,
          "bulletVelocity": 1.375,
          "vertRecoil": 0.89
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 730,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 731,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 710,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 711,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 712,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 713,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 714,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 715,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 716,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 717,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 718,
        "statMods": {
          "ads": 87,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 719,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 720,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 721,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 722,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 723,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 724,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 725,
        "statMods": {
          "ads": 108
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 726,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 727,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 728,
        "statMods": {
          "ads": 142
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 729,
        "statMods": {
          "ads": 12
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 732,
        "statMods": {
          "ads": 33,
          "movement": 0.99,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 733,
        "statMods": {
          "ads": 33,
          "movement": 0.987,
          "movementAds": 0.91,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 734,
        "statMods": {
          "ads": 37,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 741,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 742,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "25 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 735,
        "statMods": {
          "ads": 8,
          "movement": 0.997,
          "magSize": 5
        }
      },
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 736,
        "statMods": {
          "ads": 12,
          "movement": 0.972,
          "magSize": 10
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 737,
        "statMods": {
          "ads": -8,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 745,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 746,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FTAC XL Elite Comb",
        "type": [
          "stock"
        ],
        "id": 738,
        "statMods": {
          "ads": -33,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Ballast Pack",
        "type": [
          "stock"
        ],
        "id": 747,
        "statMods": {
          "movementAds": 0.88,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "Oden Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 748,
        "statMods": {
          "movementAds": 1.12,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 739,
        "statMods": {
          "ads": -46,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 743,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 744,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 740,
        "statMods": {
          "reload": -970
        }
      }
    ]
  },
  "RAM-7": {
    "barrel": [
      {
        "name": "FTAC 13.5 Compact",
        "type": [
          "barrel"
        ],
        "id": 749,
        "statMods": {
          "range": 0.9,
          "ads": -17,
          "movement": 1.012,
          "bulletVelocity": 0.82,
          "vertRecoil": 1.1,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "Forge Tac Eclipse",
        "type": [
          "barrel"
        ],
        "id": 750,
        "statMods": {
          "range": 1.27,
          "ads": 25,
          "bulletVelocity": 1.29
        }
      },
      {
        "name": "FSS Ranger",
        "type": [
          "barrel"
        ],
        "id": 751,
        "statMods": {
          "range": 1.37,
          "ads": 54,
          "movement": 0.96,
          "bulletVelocity": 1.62,
          "vertRecoil": 0.8,
          "hipfireArea": 1.8
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 752,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 753,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 754,
        "statMods": {
          "range": 1.1,
          "ads": 16,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 775,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 776,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 755,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 756,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 757,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 758,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 759,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 760,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 761,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 762,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 763,
        "statMods": {
          "ads": 62,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 764,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 765,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 766,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 767,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 768,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Cronen 2x2 Elite",
        "type": [
          "optic"
        ],
        "id": 769,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 770,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 771,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 772,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 773,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 774,
        "statMods": {
          "ads": 66
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 777,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 778,
        "statMods": {
          "ads": 21,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 779,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 785,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 786,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 780,
        "statMods": {
          "ads": 8,
          "movement": 0.986,
          "magSize": 20
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 781,
        "statMods": {
          "ads": -11,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 789,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 790,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "XRK Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 782,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Equilibrium",
        "type": [
          "stock"
        ],
        "id": 791,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "XRK Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 792,
        "statMods": {
          "movementAds": 1.17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 783,
        "statMods": {
          "ads": -33,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 787,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 788,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 784,
        "statMods": {
          "reload": -680
        }
      }
    ]
  },
  "FR 5.56 (Warzone)": {
    "barrel": [
      {
        "name": "FR 15.9 Commando",
        "type": [
          "barrel"
        ],
        "id": 1662,
        "statMods": {
          "range": 0.85,
          "ads": -21,
          "movement": 1.005,
          "bulletVelocity": 0.9,
          "vertRecoil": 1.1,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "FR 24.4 Sniper",
        "type": [
          "barrel"
        ],
        "id": 1663,
        "statMods": {
          "range": 1.3,
          "ads": 58,
          "movement": 0.967,
          "bulletVelocity": 1.47,
          "vertRecoil": 0.8,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "barrel"
        ],
        "id": 1664,
        "statMods": {
          "range": 0.87,
          "ads": -16,
          "bulletVelocity": 0.8
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1665,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1666,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1667,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1689,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1690,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1668,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1669,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1670,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1671,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1672,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1673,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1674,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1675,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1676,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1677,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1678,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1679,
        "statMods": {
          "ads": 75,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 1680,
        "statMods": {
          "ads": 104
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1681,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1682,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1683,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1684,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6.1x)",
        "type": [
          "optic"
        ],
        "id": 1685,
        "statMods": {
          "ads": 121
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1686,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1687,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1688,
        "statMods": {
          "ads": 54
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1691,
        "statMods": {
          "ads": 33,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1692,
        "statMods": {
          "ads": 33,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1693,
        "statMods": {
          "ads": 29,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1700,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1701,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mag",
        "type": [
          "ammunition"
        ],
        "id": 1694,
        "statMods": {
          "ads": 8,
          "movement": 0.981,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mag",
        "type": [
          "ammunition"
        ],
        "id": 1695,
        "statMods": {
          "ads": 8,
          "movement": 0.969,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1696,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1704,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1705,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Tac-Wrap",
        "type": [
          "stock"
        ],
        "id": 1697,
        "statMods": {
          "ads": -29,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FR Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 1706,
        "statMods": {
          "movementAds": 1.15,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Ballast Pack",
        "type": [
          "stock"
        ],
        "id": 1707,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1698,
        "statMods": {
          "ads": -42,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1702,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1703,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1699,
        "statMods": {
          "reload": -590
        }
      }
    ]
  },
  "Bruen Mk9": {
    "barrel": [
      {
        "name": "XRK Horizon 23.0",
        "type": [
          "barrel"
        ],
        "id": 793,
        "statMods": {
          "range": 1.2,
          "ads": 33,
          "bulletVelocity": 1.16
        }
      },
      {
        "name": "XRK Summit 26.8",
        "type": [
          "barrel"
        ],
        "id": 794,
        "statMods": {
          "range": 1.25,
          "ads": 58,
          "movement": 0.983,
          "bulletVelocity": 1.33,
          "vertRecoil": 0.9,
          "hipfireArea": 1.51
        }
      },
      {
        "name": "Bruen 18.0 Para",
        "type": [
          "barrel"
        ],
        "id": 795,
        "statMods": {
          "range": 0.88,
          "ads": -42,
          "movement": 1.011,
          "bulletVelocity": 0.85,
          "vertRecoil": 1.08,
          "hipfireArea": 0.79
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 796,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 797,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 798,
        "statMods": {
          "range": 1.1,
          "ads": 41,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 819,
        "statMods": {
          "ads": 13,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 820,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 799,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 800,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 801,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 802,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 803,
        "statMods": {
          "ads": 37,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 804,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 805,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 806,
        "statMods": {
          "ads": 108,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 807,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 808,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 809,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 810,
        "statMods": {
          "ads": 154
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 811,
        "statMods": {
          "ads": 92
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 812,
        "statMods": {
          "ads": 108
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 813,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 814,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 815,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 816,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 817,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 818,
        "statMods": {
          "ads": 129
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 821,
        "statMods": {
          "ads": 37,
          "movement": 0.99,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 822,
        "statMods": {
          "ads": 37,
          "movement": 0.987,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 823,
        "statMods": {
          "ads": 46,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 833,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 834,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "200 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 824,
        "statMods": {
          "ads": 33,
          "movement": 0.941,
          "magSize": 100
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 825,
        "statMods": {
          "ads": -50,
          "movement": 1.028,
          "reload": -2910,
          "magSize": -40
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 826,
        "statMods": {
          "ads": -21,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 837,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 838,
        "statMods": {
          "movementAds": 0.94,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 827,
        "statMods": {
          "ads": -38,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 828,
        "statMods": {
          "ads": -75,
          "movement": 1.04,
          "movementAds": 1.16,
          "vertRecoil": 1.31,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 829,
        "statMods": {
          "ads": -29,
          "movementAds": 1.15,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 839,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 840,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 830,
        "statMods": {
          "ads": -58,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 835,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 836,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 831,
        "statMods": {
          "reload": -900
        }
      },
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 832,
        "statMods": {
          "reload": -3430
        }
      }
    ]
  },
  "Holger-26": {
    "barrel": [
      {
        "name": "XRK Ultralight",
        "type": [
          "barrel"
        ],
        "id": 841,
        "statMods": {
          "range": 0.8,
          "ads": -50,
          "bulletVelocity": 0.79
        }
      },
      {
        "name": "FTAC 8.98 Spitfire",
        "type": [
          "barrel"
        ],
        "id": 842,
        "statMods": {
          "range": 0.8,
          "ads": -75,
          "movement": 1.042,
          "bulletVelocity": 0.67,
          "vertRecoil": 1.19,
          "hipfireArea": 0.88
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 843,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 844,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 845,
        "statMods": {
          "range": 1.1,
          "ads": 46,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 868,
        "statMods": {
          "ads": 13,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 869,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 846,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 847,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 848,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 849,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 850,
        "statMods": {
          "ads": 38,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 851,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 852,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 853,
        "statMods": {
          "ads": 100,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 854,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 855,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 856,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "FSS Integral Reflex",
        "type": [
          "optic"
        ],
        "id": 857,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 858,
        "statMods": {
          "ads": 134
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 859,
        "statMods": {
          "ads": 80
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 860,
        "statMods": {
          "ads": 96
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 861,
        "statMods": {
          "ads": 5
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 862,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 863,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 864,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 865,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 866,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "Solozero K498 4.0x Integral",
        "type": [
          "optic"
        ],
        "id": 867,
        "statMods": {
          "ads": 46
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 870,
        "statMods": {
          "ads": 34,
          "movement": 0.99,
          "movementAds": 0.9,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 871,
        "statMods": {
          "ads": 38,
          "movement": 0.987,
          "movementAds": 0.9,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 872,
        "statMods": {
          "ads": 42,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 880,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 881,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 873,
        "statMods": {
          "ads": -79,
          "tstf": -134,
          "stf": -75,
          "movement": 1.023,
          "reload": -990,
          "magSize": -70
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 874,
        "statMods": {
          "ads": -25,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 884,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 885,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 875,
        "statMods": {
          "ads": -70,
          "movement": 1.044,
          "movementAds": 1.16,
          "vertRecoil": 1.34,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "XRK Axis",
        "type": [
          "stock"
        ],
        "id": 876,
        "statMods": {
          "ads": -33,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FSS Ranger",
        "type": [
          "stock"
        ],
        "id": 886,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FSS Infantry",
        "type": [
          "stock"
        ],
        "id": 887,
        "statMods": {
          "movementAds": 1.18,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 877,
        "statMods": {
          "ads": -50,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 882,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 883,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 878,
        "statMods": {
          "reload": -1230
        }
      },
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 879,
        "statMods": {
          "reload": -1760
        }
      }
    ]
  },
  "M91": {
    "barrel": [
      {
        "name": "M91 Special Forces",
        "type": [
          "barrel"
        ],
        "id": 888,
        "statMods": {
          "range": 1.22,
          "ads": 37,
          "bulletVelocity": 1.16
        }
      },
      {
        "name": "M91 Infantry",
        "type": [
          "barrel"
        ],
        "id": 889,
        "statMods": {
          "range": 0.888,
          "ads": -42,
          "movement": 1.014,
          "bulletVelocity": 0.87,
          "vertRecoil": 1.13,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "M91 Heavy",
        "type": [
          "barrel"
        ],
        "id": 890,
        "statMods": {
          "range": 1.22,
          "ads": 42,
          "movement": 0.992,
          "bulletVelocity": 1.13,
          "vertRecoil": 0.92,
          "hipfireArea": 1.31
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 891,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 892,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 893,
        "statMods": {
          "range": 1.1,
          "ads": 37,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 914,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 915,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 894,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 895,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 896,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 897,
        "statMods": {
          "ads": 10
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 898,
        "statMods": {
          "ads": 35,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 899,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 900,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 901,
        "statMods": {
          "ads": 112,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 902,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 903,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 904,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 905,
        "statMods": {
          "ads": 150
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 906,
        "statMods": {
          "ads": 96
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 907,
        "statMods": {
          "ads": 108
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 908,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 909,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 910,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 911,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 912,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 913,
        "statMods": {
          "ads": 129
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 916,
        "statMods": {
          "ads": 37,
          "movement": 0.99,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 917,
        "statMods": {
          "ads": 37,
          "movement": 0.987,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 918,
        "statMods": {
          "ads": 46,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 926,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 927,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "120 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 919,
        "statMods": {
          "movement": 0.986,
          "magSize": 20
        }
      },
      {
        "name": "150 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 920,
        "statMods": {
          "ads": 8,
          "movement": 0.974,
          "magSize": 50
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 921,
        "statMods": {
          "ads": -25,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 930,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 931,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 922,
        "statMods": {
          "ads": -63,
          "movement": 1.044,
          "movementAds": 1.16,
          "vertRecoil": 1.32,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "XRK Striker",
        "type": [
          "stock"
        ],
        "id": 923,
        "statMods": {
          "ads": -50,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 932,
        "statMods": {
          "movementAds": 0.92,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 933,
        "statMods": {
          "movementAds": 1.16,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 924,
        "statMods": {
          "ads": -58,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 928,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 929,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 925,
        "statMods": {
          "reload": -2070
        }
      }
    ]
  },
  "MG34": {
    "barrel": [
      {
        "name": "FSS Brute",
        "type": [
          "barrel"
        ],
        "id": 934,
        "statMods": {
          "range": 1.2,
          "ads": 25,
          "bulletVelocity": 1.19
        }
      },
      {
        "name": "FSS Stubby",
        "type": [
          "barrel"
        ],
        "id": 935,
        "statMods": {
          "range": 0.8,
          "ads": -70,
          "movement": 1.019,
          "bulletVelocity": 0.71,
          "vertRecoil": 1.34,
          "hipfireArea": 0.91
        }
      },
      {
        "name": "FSS Elite",
        "type": [
          "barrel"
        ],
        "id": 936,
        "statMods": {
          "range": 1.2,
          "ads": 30,
          "bulletVelocity": 1.23
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 937,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 938,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 939,
        "statMods": {
          "range": 1.1,
          "ads": 46,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 959,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 960,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 940,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 941,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 942,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 943,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 944,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 945,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 946,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 947,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 948,
        "statMods": {
          "ads": 116,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 949,
        "statMods": {
          "ads": 41,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 950,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 951,
        "statMods": {
          "ads": 112
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 952,
        "statMods": {
          "ads": 141
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 953,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 954,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 955,
        "statMods": {
          "ads": 83
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 956,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 957,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 958,
        "statMods": {
          "ads": 162
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 961,
        "statMods": {
          "ads": 37,
          "movement": 0.99,
          "movementAds": 0.88,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 962,
        "statMods": {
          "ads": 50,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 963,
        "statMods": {
          "ads": 41,
          "movement": 0.987,
          "movementAds": 0.88,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 971,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 972,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "75 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 964,
        "statMods": {
          "ads": 8,
          "movement": 0.985,
          "magSize": 25
        }
      },
      {
        "name": "100 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 965,
        "statMods": {
          "ads": 12,
          "movement": 0.972,
          "magSize": 50
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 966,
        "statMods": {
          "ads": -17,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 975,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 976,
        "statMods": {
          "movementAds": 0.94,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 967,
        "statMods": {
          "ads": -71,
          "movement": 1.043,
          "movementAds": 1.16,
          "vertRecoil": 1.25,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 968,
        "statMods": {
          "ads": -38,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 977,
        "statMods": {
          "movementAds": 0.91,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 978,
        "statMods": {
          "movementAds": 1.12,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 969,
        "statMods": {
          "ads": -59,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 973,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 974,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 970,
        "statMods": {
          "reload": -2470
        }
      }
    ]
  },
  "PKM": {
    "barrel": [
      {
        "name": "18.2 Compact Barrel",
        "type": [
          "barrel"
        ],
        "id": 979,
        "statMods": {
          "range": 0.9375,
          "ads": -34,
          "movement": 1.01,
          "bulletVelocity": 0.85,
          "vertRecoil": 1.23,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "26.9 Extended Barrel",
        "type": [
          "barrel"
        ],
        "id": 980,
        "statMods": {
          "range": 1.1875,
          "ads": 33,
          "movement": 0.994,
          "bulletVelocity": 1.26,
          "vertRecoil": 0.97,
          "hipfireArea": 1.31
        }
      },
      {
        "name": "25.9 Heavy Barrel",
        "type": [
          "barrel"
        ],
        "id": 981,
        "statMods": {
          "range": 1.25,
          "ads": 29,
          "bulletVelocity": 1.19
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 982,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 983,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 984,
        "statMods": {
          "range": 1.1,
          "ads": 50,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1004,
        "statMods": {
          "ads": 12,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1005,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 985,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 986,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 987,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 988,
        "statMods": {
          "ads": 37,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 989,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 990,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 991,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 992,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 993,
        "statMods": {
          "ads": 108,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 994,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 995,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 996,
        "statMods": {
          "ads": 145
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 997,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 998,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 999,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1000,
        "statMods": {
          "ads": 104
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1001,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1002,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1003,
        "statMods": {
          "ads": 120
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1006,
        "statMods": {
          "ads": 33,
          "movement": 0.99,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Snatch Grip",
        "type": [
          "underbarrel"
        ],
        "id": 1007,
        "statMods": {
          "ads": -59,
          "movement": 0.97,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1008,
        "statMods": {
          "ads": 54,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1016,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1017,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "150 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 1009,
        "statMods": {
          "ads": 20,
          "movement": 0.974,
          "magSize": 50
        }
      },
      {
        "name": "200 Round Belt",
        "type": [
          "ammunition"
        ],
        "id": 1010,
        "statMods": {
          "ads": 33,
          "movement": 0.94,
          "movementAds": 0.99889,
          "magSize": 100
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1011,
        "statMods": {
          "ads": -13,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1020,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1021,
        "statMods": {
          "movementAds": 0.94,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1012,
        "statMods": {
          "ads": -38,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1013,
        "statMods": {
          "ads": -55,
          "movement": 1.042,
          "movementAds": 1.16,
          "vertRecoil": 1.29,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 1022,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1023,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1014,
        "statMods": {
          "ads": -55,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1018,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1019,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1015,
        "statMods": {
          "reload": -2620
        }
      }
    ]
  },
  "SA87": {
    "barrel": [
      {
        "name": "SA87 18.2 Factory",
        "type": [
          "barrel"
        ],
        "id": 1024,
        "statMods": {
          "range": 0.9,
          "ads": -34,
          "movement": 1.011,
          "bulletVelocity": 0.84,
          "vertRecoil": 1.12,
          "hipfireArea": 0.96
        }
      },
      {
        "name": "SA87 25.4 Factory",
        "type": [
          "barrel"
        ],
        "id": 1025,
        "statMods": {
          "range": 1.2,
          "ads": 37,
          "movement": 0.993,
          "bulletVelocity": 1.26,
          "vertRecoil": 0.95,
          "hipfireArea": 1.31
        }
      },
      {
        "name": "SA87 12.4 Factory",
        "type": [
          "barrel"
        ],
        "id": 1026,
        "statMods": {
          "range": 0.8,
          "ads": -63,
          "movement": 1.019,
          "bulletVelocity": 0.69,
          "vertRecoil": 1.13,
          "hipfireArea": 0.91
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1027,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1028,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1029,
        "statMods": {
          "range": 1.1,
          "ads": 41,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1049,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1050,
        "statMods": {
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1030,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1031,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1032,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1033,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1034,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1035,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1036,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1037,
        "statMods": {
          "ads": 75,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1038,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1039,
        "statMods": {
          "ads": 27,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1040,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1041,
        "statMods": {}
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 1042,
        "statMods": {
          "ads": 129
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1043,
        "statMods": {
          "ads": 91
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1044,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1045,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1046,
        "statMods": {
          "ads": 104
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1047,
        "statMods": {}
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1048,
        "statMods": {
          "ads": 62
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1051,
        "statMods": {
          "ads": 20,
          "movement": 0.99,
          "movementAds": 0.9,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1052,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1053,
        "statMods": {
          "ads": 20,
          "movement": 0.987,
          "movementAds": 0.9,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1060,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1061,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1054,
        "statMods": {
          "movement": 0.986,
          "magSize": 20
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1055,
        "statMods": {
          "movement": 0.971,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1056,
        "statMods": {
          "ads": -34,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1064,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1065,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "Forge Tac CQB Comb",
        "type": [
          "stock"
        ],
        "id": 1057,
        "statMods": {
          "ads": -50
        }
      },
      {
        "name": "XRK SA87 Heavy Stock Pro",
        "type": [
          "stock"
        ],
        "id": 1066,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "SA87 Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 1067,
        "statMods": {
          "movementAds": 1.13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1058,
        "statMods": {
          "ads": -67,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1062,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1063,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1059,
        "statMods": {
          "reload": -1140
        }
      }
    ]
  },
  "FiNN LMG": {
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1844,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1845,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1846,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1847,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1848,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1849,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1850,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1851,
        "statMods": {
          "ads": 87,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1852,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1853,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1854,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 1855,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1856,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1857,
        "statMods": {
          "ads": 87
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1858,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1859,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1860,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1861,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1862,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1863,
        "statMods": {
          "ads": 117
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1864,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1865,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1866,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1867,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.7,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1868,
        "statMods": {
          "range": 1.1,
          "ads": 33,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "XRK Pro Twist",
        "type": [
          "barrel"
        ],
        "id": 1869,
        "statMods": {
          "range": 1.167,
          "ads": 17,
          "bulletVelocity": 1.16,
          "hipfireArea": 1.013
        }
      },
      {
        "name": "XRK LongShot Advantage",
        "type": [
          "barrel"
        ],
        "id": 1870,
        "statMods": {
          "range": 1.26,
          "ads": 50,
          "movement": 0.973,
          "bulletVelocity": 1.42,
          "vertRecoil": 0.91,
          "hipfireArea": 1.675
        }
      },
      {
        "name": "FTAC VC-8 Harrier",
        "type": [
          "barrel"
        ],
        "id": 1871,
        "statMods": {
          "range": 0.867,
          "ads": -25,
          "bulletVelocity": 0.85
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1872,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1873,
        "statMods": {
          "ads": 29,
          "movement": 0.99,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1874,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1875,
        "statMods": {
          "ads": 29,
          "movement": 0.987,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1876,
        "statMods": {
          "ads": 33,
          "vertRecoil": 0.83
        }
      }
    ],
    "ammunition": [
      {
        "name": "5.56 NATO 100-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1877,
        "statMods": {
          "ads": 33,
          "movement": 0.98,
          "magSize": 25
        }
      },
      {
        "name": "5.56 CT 75-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1878,
        "statMods": {
          "ads": -13,
          "movement": 1.016,
          "bulletVelocity": 0.57
        }
      },
      {
        "name": "5.56 CT 100-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1879,
        "statMods": {
          "ads": 8,
          "bulletVelocity": 0.57,
          "magSize": 25
        }
      }
    ],
    "stock": [
      {
        "name": "XRK Citadel",
        "type": [
          "stock"
        ],
        "id": 1880,
        "statMods": {
          "movementAds": 0.9
        }
      },
      {
        "name": "XRK M309 Forward Assault",
        "type": [
          "stock"
        ],
        "id": 1881,
        "statMods": {
          "movementAds": 1.12
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1882,
        "statMods": {
          "ads": -50,
          "movement": 1.044,
          "movementAds": 1.16,
          "vertRecoil": 1.3,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "XRK ChainSAW",
        "type": [
          "stock"
        ],
        "id": 1883,
        "statMods": {
          "tstf": -187,
          "stf": -125,
          "movement": 1.019,
          "hipfireArea": 0.33
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1884,
        "statMods": {
          "ads": -46,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1885,
        "statMods": {
          "reload": -1540
        }
      }
    ]
  },
  "FiNN LMG Factory Adverse": {
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1886,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1887,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1888,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1889,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1890,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1891,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1892,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1893,
        "statMods": {
          "ads": 87,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1894,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1895,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1896,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Sniper Scope (4.4x)",
        "type": [
          "optic"
        ],
        "id": 1897,
        "statMods": {
          "ads": 117
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1898,
        "statMods": {
          "ads": 71
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1899,
        "statMods": {
          "ads": 87
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1900,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1901,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1902,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1903,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1904,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1905,
        "statMods": {
          "ads": 117
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1906,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1907,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1908,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1909,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.7,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1910,
        "statMods": {
          "range": 1.1,
          "ads": 33,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "Factory Adverse (Default)",
        "type": [
          "barrel"
        ],
        "id": 1911,
        "statMods": {}
      },
      {
        "name": "XRK Pro Twist Adverse",
        "type": [
          "barrel"
        ],
        "id": 1912,
        "statMods": {
          "range": 1.19,
          "ads": 25,
          "bulletVelocity": 1.23,
          "hipfireArea": 1.013
        }
      },
      {
        "name": "XRK LongShot Adverse",
        "type": [
          "barrel"
        ],
        "id": 1913,
        "statMods": {
          "range": 1.34,
          "ads": 62,
          "movement": 0.973,
          "bulletVelocity": 1.59,
          "vertRecoil": 0.82,
          "hipfireArea": 1.72
        }
      },
      {
        "name": "FLTAC VC-8 Harrier Adverse",
        "type": [
          "barrel"
        ],
        "id": 1914,
        "statMods": {
          "range": 0.9,
          "ads": -29,
          "bulletVelocity": 0.82,
          "hipfireArea": 1.035
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1915,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1916,
        "statMods": {
          "ads": 29,
          "movement": 0.99,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1917,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1918,
        "statMods": {
          "ads": 29,
          "movement": 0.987,
          "movementAds": 0.89,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1919,
        "statMods": {
          "ads": 33,
          "vertRecoil": 0.83
        }
      }
    ],
    "ammunition": [
      {
        "name": "5.56 NATO 100-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1920,
        "statMods": {
          "ads": 33,
          "movement": 0.98,
          "magSize": 25
        }
      },
      {
        "name": "5.56 CT 75-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1921,
        "statMods": {
          "ads": -13,
          "movement": 1.016,
          "bulletVelocity": 0.57
        }
      },
      {
        "name": "5.56 CT 100-Round Belts",
        "type": [
          "ammunition"
        ],
        "id": 1922,
        "statMods": {
          "ads": 8,
          "bulletVelocity": 0.57,
          "magSize": 25
        }
      }
    ],
    "stock": [
      {
        "name": "XRK Citadel",
        "type": [
          "stock"
        ],
        "id": 1923,
        "statMods": {
          "movementAds": 0.9
        }
      },
      {
        "name": "XRK M309 Forward Assault",
        "type": [
          "stock"
        ],
        "id": 1924,
        "statMods": {
          "movementAds": 1.12
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1925,
        "statMods": {
          "ads": -50,
          "movement": 1.044,
          "movementAds": 1.16,
          "vertRecoil": 1.3,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "XRK ChainSAW",
        "type": [
          "stock"
        ],
        "id": 1926,
        "statMods": {
          "tstf": -187,
          "stf": -125,
          "movement": 1.019,
          "hipfireArea": 0.33
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1927,
        "statMods": {
          "ads": -46,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1928,
        "statMods": {
          "reload": -1540
        }
      }
    ]
  },
  "AUG": {
    "barrel": [
      {
        "name": "407mm Extended",
        "type": [
          "barrel"
        ],
        "id": 1068,
        "statMods": {
          "range": 1.31,
          "ads": 41,
          "bulletVelocity": 1.16,
          "vertRecoil": 0.69,
          "hipfireArea": 1.47
        }
      },
      {
        "name": "407mm Lightweight",
        "type": [
          "barrel"
        ],
        "id": 1069,
        "statMods": {
          "range": 1.2,
          "ads": 20,
          "bulletVelocity": 1.22
        }
      },
      {
        "name": "622mm Long Barrel",
        "type": [
          "barrel"
        ],
        "id": 1070,
        "statMods": {
          "range": 1.45,
          "ads": 58,
          "movement": 0.956,
          "bulletVelocity": 1.55,
          "vertRecoil": 0.66,
          "hipfireArea": 1.77
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1071,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1072,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1073,
        "statMods": {
          "range": 1.1,
          "ads": 25,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1093,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1094,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1074,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1075,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1076,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1077,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1078,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1079,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1080,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1081,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1082,
        "statMods": {
          "ads": 70
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1083,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1084,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1085,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1086,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1087,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1088,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1089,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1090,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1091,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1092,
        "statMods": {
          "ads": 41
        }
      }
    ],
    "ammunition": [
      {
        "name": "32 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1095,
        "statMods": {
          "ads": 4,
          "movement": 0.988,
          "magSize": 7
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1096,
        "statMods": {
          "ads": 16,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1097,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1098,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1103,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1104,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1099,
        "statMods": {
          "ads": -13,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1107,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1108,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC CQB Comb",
        "type": [
          "stock"
        ],
        "id": 1100,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 1109,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FSS Heavy Stock Pro",
        "type": [
          "stock"
        ],
        "id": 1110,
        "statMods": {
          "movementAds": 0.94,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1101,
        "statMods": {
          "ads": -30,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1105,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1106,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1102,
        "statMods": {
          "reload": -650
        }
      }
    ]
  },
  "AUG 5.56": {
    "barrel": [
      {
        "name": "407mm Extended",
        "type": [
          "barrel"
        ],
        "id": 1111,
        "statMods": {
          "range": 1.333,
          "ads": 62,
          "bulletVelocity": 1.16,
          "vertRecoil": 0.69,
          "hipfireArea": 1.47
        }
      },
      {
        "name": "407mm Lightweight",
        "type": [
          "barrel"
        ],
        "id": 1112,
        "statMods": {
          "range": 1.2,
          "ads": 25,
          "bulletVelocity": 1.22
        }
      },
      {
        "name": "622mm Long Barrel",
        "type": [
          "barrel"
        ],
        "id": 1113,
        "statMods": {
          "range": 1.45,
          "ads": 87,
          "movement": 0.956,
          "bulletVelocity": 1.55,
          "vertRecoil": 0.66,
          "hipfireArea": 1.77
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1114,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1115,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1116,
        "statMods": {
          "range": 1.1,
          "ads": 33,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1136,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1137,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1117,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1118,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1119,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1120,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1121,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1122,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1123,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1124,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1125,
        "statMods": {
          "ads": 70
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1126,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1127,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1128,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1129,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1130,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1131,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Integral 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1132,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1133,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1134,
        "statMods": {
          "ads": 62
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1135,
        "statMods": {
          "ads": 41
        }
      }
    ],
    "ammunition": [
      {
        "name": "60 Round Drum",
        "type": [
          "ammunition"
        ],
        "id": 1138,
        "statMods": {
          "ads": 45,
          "movement": 0.943,
          "magSize": 30
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1139,
        "statMods": {
          "ads": 16,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1140,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1141,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1145,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1146,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.73,
          "ySway": 0.8
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1142,
        "statMods": {
          "ads": -13,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1150,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1151,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC CQB Comb",
        "type": [
          "stock"
        ],
        "id": 1143,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Ultralight Hollow",
        "type": [
          "stock"
        ],
        "id": 1152,
        "statMods": {
          "movementAds": 1.14,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FSS Heavy Stock Pro",
        "type": [
          "stock"
        ],
        "id": 1153,
        "statMods": {
          "movementAds": 0.94,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1144,
        "statMods": {
          "xSway": 0.55,
          "ySway": 0.6,
          "reload": -650
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1147,
        "statMods": {
          "ads": -30,
          "xSway": 0.6,
          "ySway": 0.64
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1148,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1149,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ]
  },
  "Fennec": {
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1154,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1155,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1156,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1157,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1158,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1159,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1160,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1161,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1162,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1163,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1164,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1165,
        "statMods": {
          "ads": 63,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1166,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1167,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1168,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1169,
        "statMods": {
          "ads": 59
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1170,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1171,
        "statMods": {
          "ads": 34
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1172,
        "statMods": {
          "ads": 9,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1173,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1174,
        "statMods": {
          "range": 1.1,
          "ads": 30,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "ZLR Sabre",
        "type": [
          "muzzle"
        ],
        "id": 1175,
        "statMods": {
          "range": 1.27,
          "ads": 42,
          "bulletVelocity": 1.2,
          "vertRecoil": 0.88
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1176,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "barrel": [
      {
        "name": "ZLR 16 Apex",
        "type": [
          "barrel"
        ],
        "id": 1177,
        "statMods": {
          "range": 1.36,
          "ads": 46,
          "movement": 0.967,
          "bulletVelocity": 1.23,
          "vertRecoil": 0.85,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "ZLR 18 Deadfall",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 1178,
        "statMods": {
          "range": 1.45,
          "ads": 67,
          "movement": 0.957,
          "bulletVelocity": 1.37,
          "vertRecoil": 0.85,
          "hipfireArea": 1.83
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1179,
        "statMods": {
          "ads": 25,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1180,
        "statMods": {
          "ads": 30,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1181,
        "statMods": {
          "ads": 25,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1188,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1189,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "40 Round Drum Mags",
        "type": [
          "ammunition"
        ],
        "id": 1182,
        "statMods": {
          "ads": 9,
          "magSize": 15
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1183,
        "statMods": {
          "ads": -4,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1192,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1193,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC CQS",
        "type": [
          "stock"
        ],
        "id": 1184,
        "statMods": {
          "ads": -8,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1185,
        "statMods": {
          "ads": -20,
          "movement": 1.02,
          "movementAds": 1.11,
          "vertRecoil": 1.23,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FTAC C6 Carbine PRO",
        "type": [
          "stock"
        ],
        "id": 1194,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "ZLR Blade",
        "type": [
          "stock"
        ],
        "id": 1195,
        "statMods": {
          "movementAds": 0.88,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1186,
        "statMods": {
          "ads": -16,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1190,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1191,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1187,
        "statMods": {
          "reload": -580
        }
      }
    ]
  },
  "Fennec .45 Hollow Point 12-R Mags": {
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1196,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1197,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1198,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1199,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1200,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1201,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1202,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1203,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1204,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1205,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1206,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1207,
        "statMods": {
          "ads": 63,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1208,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1209,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1210,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1211,
        "statMods": {
          "ads": 59
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1212,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1213,
        "statMods": {
          "ads": 34
        }
      }
    ],
    "muzzle": [
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1214,
        "statMods": {
          "ads": 9,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1215,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1216,
        "statMods": {
          "range": 1.1,
          "ads": 30,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "ZLR Sabre",
        "type": [
          "muzzle"
        ],
        "id": 1217,
        "statMods": {
          "range": 1.27,
          "ads": 42,
          "vertRecoil": 0.88
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1218,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "barrel": [
      {
        "name": "ZLR 16 Apex",
        "type": [
          "barrel"
        ],
        "id": 1219,
        "statMods": {
          "range": 1.36,
          "ads": 46,
          "movement": 0.967,
          "bulletVelocity": 1.23,
          "vertRecoil": 0.85,
          "hipfireArea": 1.67
        }
      },
      {
        "name": "ZLR 18 Deadfall",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 1220,
        "statMods": {
          "range": 1.45,
          "ads": 67,
          "movement": 0.957,
          "bulletVelocity": 1.37,
          "vertRecoil": 0.85,
          "hipfireArea": 1.83
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1221,
        "statMods": {
          "ads": 25,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1222,
        "statMods": {
          "ads": 30,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1223,
        "statMods": {
          "ads": 25,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1229,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1230,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1224,
        "statMods": {
          "ads": -4,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1233,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1234,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC CQS",
        "type": [
          "stock"
        ],
        "id": 1225,
        "statMods": {
          "ads": -8,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1226,
        "statMods": {
          "ads": -20,
          "movement": 1.02,
          "movementAds": 1.11,
          "vertRecoil": 1.23,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FTAC C6 Carbine PRO",
        "type": [
          "stock"
        ],
        "id": 1235,
        "statMods": {
          "movementAds": 0.95,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "ZLR Blade",
        "type": [
          "stock"
        ],
        "id": 1236,
        "statMods": {
          "movementAds": 0.88,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1227,
        "statMods": {
          "ads": -16,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1231,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1232,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1228,
        "statMods": {
          "reload": -1020
        }
      }
    ]
  },
  "ISO": {
    "optic": [
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1237,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1238,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1239,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex",
        "type": [
          "optic"
        ],
        "id": 1240,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1241,
        "statMods": {
          "ads": 17,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic",
        "type": [
          "optic"
        ],
        "id": 1242,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex",
        "type": [
          "optic"
        ],
        "id": 1243,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1244,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1245,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1246,
        "statMods": {
          "ads": 54,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1247,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1248,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1249,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1250,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1251,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1252,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1253,
        "statMods": {
          "ads": 62
        }
      }
    ],
    "muzzle": [
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1254,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1255,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1256,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1257,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1258,
        "statMods": {
          "range": 1.1,
          "ads": 21,
          "bulletVelocity": 1.15
        }
      }
    ],
    "barrel": [
      {
        "name": "FTAC 225 Dominator",
        "type": [
          "barrel"
        ],
        "id": 1259,
        "statMods": {
          "range": 1.182,
          "ads": 12,
          "bulletVelocity": 1.325
        }
      },
      {
        "name": "FSS Revolution",
        "type": [
          "barrel"
        ],
        "id": 1260,
        "statMods": {
          "range": 1.345,
          "ads": 37,
          "movement": 0.973,
          "bulletVelocity": 1.22,
          "vertRecoil": 0.88,
          "hipfireArea": 1.65
        }
      },
      {
        "name": "ISO 140mm CQB",
        "type": [
          "barrel"
        ],
        "id": 1261,
        "statMods": {
          "range": 0.9,
          "ads": -13,
          "movement": 1.01,
          "bulletVelocity": 0.86,
          "vertRecoil": 1.07,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "FSS Nightshade",
        "type": [
          "barrel",
          "muzzle"
        ],
        "id": 1262,
        "statMods": {
          "range": 1.42,
          "ads": 54,
          "movement": 0.96,
          "bulletVelocity": 1.79,
          "vertRecoil": 0.8,
          "hipfireArea": 1.82
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1263,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1264,
        "statMods": {
          "ads": 21,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1265,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1266,
        "statMods": {
          "ads": 21,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1267,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.83
        }
      }
    ],
    "ammunition": [
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1268,
        "statMods": {
          "ads": 4,
          "movement": 0.986,
          "magSize": 10
        }
      },
      {
        "name": "50 Round Drums",
        "type": [
          "ammunition"
        ],
        "id": 1269,
        "statMods": {
          "ads": 42,
          "movement": 0.949,
          "magSize": 30
        }
      }
    ],
    "rear grip": [
      {
        "name": "FSS Vice ISO Grip",
        "type": [
          "rear grip"
        ],
        "id": 1270,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      },
      {
        "name": "FTAC Elite ISO Grip",
        "type": [
          "rear grip"
        ],
        "id": 1271,
        "statMods": {
          "ads": -8,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "ISO Tac-Form",
        "type": [
          "rear grip"
        ],
        "id": 1272,
        "statMods": {
          "vertRecoil": 0.94,
          "xSway": 1.1,
          "ySway": 1.2
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1273,
        "statMods": {
          "movementAds": 1.12,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 1274,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FTAC Vagrant",
        "type": [
          "stock"
        ],
        "id": 1275,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "ISO Collapsible",
        "type": [
          "stock"
        ],
        "id": 1276,
        "statMods": {
          "ads": -33,
          "movement": 1.021,
          "movementAds": 1.2,
          "vertRecoil": 1.25,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      }
    ],
    "laser": [
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1277,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1278,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      },
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1279,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1280,
        "statMods": {
          "reload": -855
        }
      }
    ]
  },
  "MP5": {
    "barrel": [
      {
        "name": "FSS Light",
        "type": [
          "barrel"
        ],
        "id": 1281,
        "statMods": {
          "range": 0.95,
          "ads": -21,
          "bulletVelocity": 0.81
        }
      },
      {
        "name": "Monolithic Integral Suppressor",
        "type": [
          "barrel"
        ],
        "id": 1282,
        "statMods": {
          "range": 1.123,
          "ads": 21,
          "bulletVelocity": 1.25
        }
      },
      {
        "name": "FSS Mini",
        "type": [
          "barrel"
        ],
        "id": 1283,
        "statMods": {
          "range": 0.793,
          "ads": -37,
          "movement": 1.022,
          "bulletVelocity": 0.64,
          "vertRecoil": 1.3,
          "hipfireArea": 0.93
        }
      },
      {
        "name": "Subsonic Integral Suppressor",
        "type": [
          "barrel"
        ],
        "id": 1284,
        "statMods": {
          "range": 0.615,
          "ads": -25,
          "bulletVelocity": 0.7
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1285,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1286,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1287,
        "statMods": {
          "range": 1.1,
          "ads": 17,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1306,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1307,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1288,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1289,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1290,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1291,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1292,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1293,
        "statMods": {
          "ads": 54,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1294,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1295,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1296,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1297,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1298,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1299,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1300,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1301,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1302,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1303,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1304,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1305,
        "statMods": {
          "ads": 4
        }
      }
    ],
    "ammunition": [
      {
        "name": "45 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1308,
        "statMods": {
          "ads": 8,
          "movement": 0.986,
          "magSize": 15
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1309,
        "statMods": {
          "ads": 17,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1310,
        "statMods": {
          "ads": 21,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1311,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1317,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1318,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1312,
        "statMods": {
          "ads": -13,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1321,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1322,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1313,
        "statMods": {
          "ads": -17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Collapsible",
        "type": [
          "stock"
        ],
        "id": 1314,
        "statMods": {
          "ads": -29,
          "movement": 1.02,
          "movementAds": 1.1,
          "vertRecoil": 1.21,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1323,
        "statMods": {
          "movementAds": 1.08,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Classic Straight-line Stock",
        "type": [
          "stock"
        ],
        "id": 1324,
        "statMods": {
          "movementAds": 0.88,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1315,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1319,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1320,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1316,
        "statMods": {
          "reload": -980
        }
      }
    ]
  },
  "MP5 10mm": {
    "barrel": [
      {
        "name": "FSS Light",
        "type": [
          "barrel"
        ],
        "id": 1325,
        "statMods": {
          "range": 0.95,
          "ads": -21,
          "bulletVelocity": 0.81
        }
      },
      {
        "name": "Monolithic Integral Suppressor",
        "type": [
          "barrel"
        ],
        "id": 1326,
        "statMods": {
          "range": 1.123,
          "ads": 21,
          "bulletVelocity": 1.25
        }
      },
      {
        "name": "FSS Mini",
        "type": [
          "barrel"
        ],
        "id": 1327,
        "statMods": {
          "range": 0.793,
          "ads": -37,
          "movement": 1.022,
          "bulletVelocity": 0.64,
          "vertRecoil": 1.3,
          "hipfireArea": 0.93
        }
      },
      {
        "name": "Subsonic Integral Suppressor",
        "type": [
          "barrel"
        ],
        "id": 1328,
        "statMods": {
          "range": 0.615,
          "ads": -25,
          "bulletVelocity": 0.7
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1329,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1330,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1331,
        "statMods": {
          "range": 1.1,
          "ads": 17,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1350,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1351,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1332,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1333,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1334,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1335,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1336,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1337,
        "statMods": {
          "ads": 54,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1338,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1339,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1340,
        "statMods": {
          "ads": 13,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1341,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1342,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1343,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1344,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1345,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1346,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1347,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1348,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1349,
        "statMods": {
          "ads": 4
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1352,
        "statMods": {
          "ads": 17,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1353,
        "statMods": {
          "ads": 21,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1354,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1360,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1361,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1355,
        "statMods": {
          "ads": -13,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1364,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1365,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1356,
        "statMods": {
          "ads": -17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Collapsible",
        "type": [
          "stock"
        ],
        "id": 1357,
        "statMods": {
          "ads": -29,
          "movement": 1.02,
          "movementAds": 1.1,
          "vertRecoil": 1.21,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1366,
        "statMods": {
          "movementAds": 1.08,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Classic Straight-line Stock",
        "type": [
          "stock"
        ],
        "id": 1367,
        "statMods": {
          "movementAds": 0.88,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1358,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1362,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1363,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1359,
        "statMods": {
          "reload": -980
        }
      }
    ]
  },
  "MP7": {
    "barrel": [
      {
        "name": "FSS Strike",
        "type": [
          "barrel"
        ],
        "id": 1368,
        "statMods": {
          "range": 1.2,
          "ads": 12,
          "bulletVelocity": 1.26
        }
      },
      {
        "name": "FSS Recon",
        "type": [
          "barrel"
        ],
        "id": 1369,
        "statMods": {
          "range": 1.34,
          "ads": 29,
          "movement": 0.981,
          "bulletVelocity": 1.37,
          "vertRecoil": 0.92,
          "hipfireArea": 1.5
        }
      },
      {
        "name": "FSS Swat",
        "type": [
          "barrel"
        ],
        "id": 1370,
        "statMods": {
          "range": 0.8,
          "ads": -21,
          "bulletVelocity": 0.81
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1371,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1372,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1373,
        "statMods": {
          "range": 1.1,
          "ads": 22,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1374,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1375,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1376,
        "statMods": {
          "ads": -25,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1405,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1406,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "optic": [
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1377,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1378,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1379,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1380,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1381,
        "statMods": {
          "ads": 17,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1382,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1383,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1384,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1385,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1386,
        "statMods": {
          "ads": 45,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1387,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1388,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1389,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1390,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1391,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1392,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1393,
        "statMods": {
          "ads": 62
        }
      }
    ],
    "stock": [
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1394,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1395,
        "statMods": {
          "ads": -21,
          "movement": 1.021,
          "movementAds": 1.11,
          "vertRecoil": 1.38,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1409,
        "statMods": {
          "movementAds": 1.11,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FORGE TAC Stalker",
        "type": [
          "stock"
        ],
        "id": 1410,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1396,
        "statMods": {
          "ads": 12,
          "movement": 0.99,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1397,
        "statMods": {
          "ads": 8,
          "movement": 0.987,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1398,
        "statMods": {
          "ads": 17,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1403,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1404,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1399,
        "statMods": {
          "ads": 8,
          "movement": 0.987,
          "magSize": 10
        }
      },
      {
        "name": "60 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1400,
        "statMods": {
          "ads": 8,
          "movement": 0.958,
          "magSize": 20
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1401,
        "statMods": {
          "ads": -8,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1407,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1408,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1402,
        "statMods": {
          "reload": -460
        }
      }
    ]
  },
  "P90": {
    "barrel": [
      {
        "name": "FSS 10.6 Pro",
        "type": [
          "barrel"
        ],
        "id": 1411,
        "statMods": {
          "range": 1.22,
          "ads": 17,
          "bulletVelocity": 1.06
        }
      },
      {
        "name": "Forge Tac Retribution",
        "type": [
          "barrel"
        ],
        "id": 1412,
        "statMods": {
          "range": 1.38,
          "ads": 37,
          "movement": 0.968,
          "bulletVelocity": 2.12,
          "vertRecoil": 0.69,
          "hipfireArea": 1.67
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1413,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1414,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1415,
        "statMods": {
          "range": 1.1,
          "ads": 21,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1435,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1436,
        "statMods": {
          "ads": 8,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1416,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1417,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1418,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1419,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1420,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1421,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1422,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1423,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "FSS Ring Sight",
        "type": [
          "optic"
        ],
        "id": 1424,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1425,
        "statMods": {
          "ads": 17,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1426,
        "statMods": {
          "ads": 50,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1427,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1428,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1429,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1430,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1431,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1432,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1433,
        "statMods": {
          "ads": 58
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1434,
        "statMods": {
          "ads": 50
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Stippled Grip Tape 1",
        "type": [
          "underbarrel"
        ],
        "id": 1437,
        "statMods": {
          "ads": 17,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Rubberized Grip Tape 1",
        "type": [
          "underbarrel"
        ],
        "id": 1438,
        "statMods": {
          "ads": 21,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Granulated Grip Tape 1",
        "type": [
          "underbarrel"
        ],
        "id": 1443,
        "statMods": {
          "movement": 0.991,
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1439,
        "statMods": {
          "ads": -9,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1446,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1447,
        "statMods": {
          "movementAds": 0.96,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "FORGE TAC CQB Comb",
        "type": [
          "stock"
        ],
        "id": 1440,
        "statMods": {
          "ads": -17,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Fly Strap",
        "type": [
          "stock"
        ],
        "id": 1448,
        "statMods": {
          "tstf": -38,
          "stf": -13,
          "movementAds": 1.09,
          "xSway": 1.3,
          "ySway": 1.5
        }
      },
      {
        "name": "FSS Heavy Stock Pro",
        "type": [
          "stock"
        ],
        "id": 1449,
        "statMods": {
          "movementAds": 0.87,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1441,
        "statMods": {
          "ads": -29,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1444,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1445,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1442,
        "statMods": {
          "reload": -640
        }
      }
    ]
  },
  "PP19 Bizon": {
    "barrel": [
      {
        "name": "8.7 Steel",
        "type": [
          "barrel"
        ],
        "id": 1450,
        "statMods": {
          "range": 1.29,
          "ads": 33,
          "movement": 0.977,
          "bulletVelocity": 1.16,
          "vertRecoil": 0.84,
          "hipfireArea": 1.47
        }
      },
      {
        "name": "8.7 Polygonal",
        "type": [
          "barrel"
        ],
        "id": 1451,
        "statMods": {
          "range": 1.2,
          "ads": 17,
          "bulletVelocity": 1.2
        }
      },
      {
        "name": "8.7 Aluminum",
        "type": [
          "barrel"
        ],
        "id": 1452,
        "statMods": {
          "bulletVelocity": 0.82
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1453,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1454,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1455,
        "statMods": {
          "range": 1.1,
          "ads": 24,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1456,
        "statMods": {
          "ads": 13,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1457,
        "statMods": {
          "ads": 8,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1458,
        "statMods": {
          "ads": -33,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1485,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1486,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "optic": [
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1459,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1460,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1461,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1462,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1463,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1464,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1465,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1466,
        "statMods": {
          "ads": 63,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1467,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1468,
        "statMods": {
          "ads": 63
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1469,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1470,
        "statMods": {
          "ads": 75
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1471,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1472,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1473,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1474,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1475,
        "statMods": {
          "ads": 38
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1476,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1477,
        "statMods": {
          "ads": -33,
          "movementAds": 1.11,
          "vertRecoil": 1.31,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "Corvus Skeleton Stock",
        "type": [
          "stock"
        ],
        "id": 1478,
        "statMods": {
          "ads": -8,
          "movementAds": 1.17,
          "xSway": 1.4,
          "ySway": 1.4
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1479,
        "statMods": {
          "ads": -21,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Factory Aluminum Stock",
        "type": [
          "stock"
        ],
        "id": 1483,
        "statMods": {
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "ammunition": [
      {
        "name": "84 Round Helical Mags",
        "type": [
          "ammunition"
        ],
        "id": 1480,
        "statMods": {
          "ads": 4,
          "magSize": 20
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1481,
        "statMods": {
          "ads": -8,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1487,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1488,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1482,
        "statMods": {
          "reload": -610
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Granulated Grip Tape",
        "type": [
          "underbarrel"
        ],
        "id": 1484,
        "statMods": {
          "movement": 0.991,
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ]
  },
  "Striker-45": {
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1489,
        "statMods": {
          "reload": -840
        }
      }
    ],
    "barrel": [
      {
        "name": "300mm Poly Barrel",
        "type": [
          "barrel"
        ],
        "id": 1490,
        "statMods": {
          "range": 1.1,
          "ads": 16,
          "bulletVelocity": 1.2
        }
      },
      {
        "name": "400mm Stainless Steel",
        "type": [
          "barrel"
        ],
        "id": 1491,
        "statMods": {
          "range": 1.35,
          "ads": 41,
          "movement": 0.972,
          "bulletVelocity": 1.18,
          "vertRecoil": 0.85,
          "hipfireArea": 1.63
        }
      },
      {
        "name": "150mm Stainless Steel",
        "type": [
          "barrel"
        ],
        "id": 1492,
        "statMods": {
          "range": 0.8,
          "ads": -34,
          "movement": 1.021,
          "bulletVelocity": 0.65,
          "vertRecoil": 1.05,
          "hipfireArea": 0.91
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1493,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1494,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1495,
        "statMods": {
          "range": 1.1,
          "ads": 23,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1515,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1516,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1496,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1497,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1498,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1499,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1500,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1501,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1502,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1503,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1504,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1505,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1506,
        "statMods": {
          "ads": 50,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1507,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1508,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1509,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1510,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1511,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1512,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1513,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1514,
        "statMods": {
          "ads": 66
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1517,
        "statMods": {
          "ads": 25,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1518,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1519,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1524,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1525,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "45 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1520,
        "statMods": {
          "ads": 8,
          "magSize": 20
        }
      }
    ],
    "rear grip": [
      {
        "name": "FTAC G-5 EXO",
        "type": [
          "rear grip"
        ],
        "id": 1521,
        "statMods": {
          "ads": -4,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1528,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1529,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "XRK Gen III Survivalist Series",
        "type": [
          "stock"
        ],
        "id": 1522,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FSS Guardian",
        "type": [
          "stock"
        ],
        "id": 1530,
        "statMods": {
          "ads": 21,
          "movementAds": 1.09,
          "xSway": 0.35,
          "ySway": 0.45
        }
      },
      {
        "name": "FTAC Precision Fixed Stock",
        "type": [
          "stock"
        ],
        "id": 1531,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1523,
        "statMods": {
          "ads": -21,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1526,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1527,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ]
  },
  "Striker-45 .45 ACP Burst": {
    "barrel": [
      {
        "name": "300mm Poly Barrel",
        "type": [
          "barrel"
        ],
        "id": 1532,
        "statMods": {
          "range": 1.1,
          "ads": 16,
          "bulletVelocity": 1.2
        }
      },
      {
        "name": "400mm Stainless Steel",
        "type": [
          "barrel"
        ],
        "id": 1533,
        "statMods": {
          "range": 1.35,
          "ads": 41,
          "movement": 0.972,
          "bulletVelocity": 1.18,
          "vertRecoil": 0.85,
          "hipfireArea": 1.63
        }
      },
      {
        "name": "150mm Stainless Steel",
        "type": [
          "barrel"
        ],
        "id": 1534,
        "statMods": {
          "range": 0.8,
          "ads": -34,
          "movement": 1.021,
          "bulletVelocity": 0.65,
          "vertRecoil": 1.05,
          "hipfireArea": 0.91
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1535,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1536,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1537,
        "statMods": {
          "range": 1.1,
          "ads": 23,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1557,
        "statMods": {
          "ads": 8,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1558,
        "statMods": {
          "ads": 12,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1538,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1539,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1540,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1541,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1542,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1543,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1544,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1545,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1546,
        "statMods": {
          "ads": 16
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 1547,
        "statMods": {
          "ads": 46
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1548,
        "statMods": {
          "ads": 50,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1549,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1550,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1551,
        "statMods": {
          "ads": 41
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1552,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1553,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1554,
        "statMods": {
          "ads": 37
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1555,
        "statMods": {
          "ads": 29
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1556,
        "statMods": {
          "ads": 66
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1559,
        "statMods": {
          "ads": 25,
          "movement": 0.99,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1560,
        "statMods": {
          "ads": 16,
          "movement": 0.987,
          "movementAds": 0.93,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1561,
        "statMods": {
          "ads": 29,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1566,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1567,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "FTAC G-5 EXO",
        "type": [
          "rear grip"
        ],
        "id": 1562,
        "statMods": {
          "ads": -4,
          "tstf": -71,
          "stf": -34,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1570,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1571,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "XRK Gen III Survivalist Series",
        "type": [
          "stock"
        ],
        "id": 1563,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "FTAC Precision Fixed Stock",
        "type": [
          "stock"
        ],
        "id": 1572,
        "statMods": {
          "movementAds": 0.9,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FSS Guardian",
        "type": [
          "stock"
        ],
        "id": 1573,
        "statMods": {
          "ads": 21,
          "movementAds": 1.09,
          "xSway": 0.35,
          "ySway": 0.45
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1564,
        "statMods": {
          "ads": -21,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1568,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1569,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1565,
        "statMods": {
          "reload": -840
        }
      }
    ]
  },
  "Uzi": {
    "barrel": [
      {
        "name": "13.1 First Responder",
        "type": [
          "barrel"
        ],
        "id": 1574,
        "statMods": {
          "range": 1.2,
          "ads": 25,
          "bulletVelocity": 1.23
        }
      },
      {
        "name": "8.5 Factory Mini",
        "type": [
          "barrel"
        ],
        "id": 1575,
        "statMods": {
          "range": 0.9,
          "ads": -9,
          "movement": 1.008,
          "bulletVelocity": 0.82,
          "vertRecoil": 1.22,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "16.5 Factory Carbine",
        "type": [
          "barrel"
        ],
        "id": 1576,
        "statMods": {
          "range": 1.26,
          "ads": 29,
          "movement": 0.981,
          "bulletVelocity": 1.14,
          "vertRecoil": 0.86,
          "hipfireArea": 1.47
        }
      },
      {
        "name": "FSS Carbine Pro",
        "type": [
          "barrel"
        ],
        "id": 1577,
        "statMods": {
          "range": 1.28,
          "ads": 25,
          "movement": 0.983,
          "bulletVelocity": 1.16,
          "vertRecoil": 0.89,
          "hipfireArea": 1.47
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1578,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1579,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1580,
        "statMods": {
          "range": 1.1,
          "ads": 20,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1599,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1600,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1581,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1582,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1583,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1584,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1585,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1586,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1587,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1588,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1589,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1590,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1591,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1592,
        "statMods": {
          "ads": 45,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1593,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1594,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1595,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1596,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1597,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1598,
        "statMods": {
          "ads": 50
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1601,
        "statMods": {
          "ads": 8,
          "movement": 0.99,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1602,
        "statMods": {
          "ads": 8,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1603,
        "statMods": {
          "ads": 8,
          "movement": 0.987,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1611,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1612,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "ammunition": [
      {
        "name": "40 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1604,
        "statMods": {
          "ads": 12,
          "movement": 0.984,
          "magSize": 8
        }
      },
      {
        "name": "50 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 1605,
        "statMods": {
          "ads": 12,
          "movement": 0.971,
          "magSize": 18
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1606,
        "statMods": {
          "ads": -12,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1615,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1616,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1607,
        "statMods": {
          "ads": -21,
          "movement": 1.017,
          "movementAds": 1.11,
          "vertRecoil": 1.38,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1608,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Standard-Issue Wood Stock",
        "type": [
          "stock"
        ],
        "id": 1617,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1618,
        "statMods": {
          "movementAds": 1.09,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1609,
        "statMods": {
          "ads": -21,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1613,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1614,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1610,
        "statMods": {
          "reload": -710
        }
      }
    ]
  },
  "Uzi 0.41AE": {
    "barrel": [
      {
        "name": "13.1 First Responder",
        "type": [
          "barrel"
        ],
        "id": 1619,
        "statMods": {
          "range": 1.2,
          "ads": 25,
          "bulletVelocity": 1.23
        }
      },
      {
        "name": "8.5 Factory Mini",
        "type": [
          "barrel"
        ],
        "id": 1620,
        "statMods": {
          "range": 0.9,
          "ads": -9,
          "movement": 1.008,
          "bulletVelocity": 0.82,
          "vertRecoil": 1.22,
          "hipfireArea": 0.95
        }
      },
      {
        "name": "16.5 Factory Carbine",
        "type": [
          "barrel"
        ],
        "id": 1621,
        "statMods": {
          "range": 1.26,
          "ads": 29,
          "movement": 0.981,
          "bulletVelocity": 1.14,
          "vertRecoil": 0.86,
          "hipfireArea": 1.47
        }
      },
      {
        "name": "FSS Carbine Pro",
        "type": [
          "barrel"
        ],
        "id": 1622,
        "statMods": {
          "range": 1.28,
          "ads": 25,
          "movement": 0.983,
          "bulletVelocity": 1.16,
          "vertRecoil": 0.89,
          "hipfireArea": 1.47
        }
      }
    ],
    "muzzle": [
      {
        "name": "Lightweight Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1623,
        "statMods": {
          "range": 0.8,
          "bulletVelocity": 0.7
        }
      },
      {
        "name": "Tactical Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1624,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Monolithic Suppressor",
        "type": [
          "muzzle"
        ],
        "id": 1625,
        "statMods": {
          "range": 1.1,
          "ads": 20,
          "bulletVelocity": 1.15
        }
      },
      {
        "name": "Muzzle Brake",
        "type": [
          "muzzle"
        ],
        "id": 1644,
        "statMods": {
          "ads": 4,
          "horizRecoil": 0.8,
          "xSway": 1.05,
          "ySway": 1.05
        }
      },
      {
        "name": "Compensator",
        "type": [
          "muzzle"
        ],
        "id": 1645,
        "statMods": {
          "ads": 4,
          "vertRecoil": 0.73,
          "xSway": 1.05,
          "ySway": 1.05
        }
      }
    ],
    "optic": [
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1626,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1627,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1628,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1629,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1630,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 1631,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1632,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1633,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 1634,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1635,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 1636,
        "statMods": {
          "ads": 16,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1637,
        "statMods": {
          "ads": 45,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 1638,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 1639,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 1640,
        "statMods": {
          "ads": 25
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 1641,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 1642,
        "statMods": {
          "ads": 20
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 1643,
        "statMods": {
          "ads": 50
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1646,
        "statMods": {
          "ads": 8,
          "movement": 0.99,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1647,
        "statMods": {
          "ads": 8,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1648,
        "statMods": {
          "ads": 8,
          "movement": 0.987,
          "movementAds": 0.94,
          "vertRecoil": 0.83,
          "xSway": 0.82,
          "ySway": 0.84
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1654,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85,
          "xSway": 0.73,
          "ySway": 0.8
        }
      },
      {
        "name": "Tactical Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 1655,
        "statMods": {
          "movement": 0.989,
          "xSway": 0.6,
          "ySway": 0.64
        }
      }
    ],
    "rear grip": [
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1649,
        "statMods": {
          "ads": -12,
          "tstf": -34,
          "stf": -13,
          "xSway": 1.18,
          "ySway": 1.24
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1658,
        "statMods": {
          "vertRecoil": 0.96,
          "xSway": 1.1,
          "ySway": 1.2
        }
      },
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 1659,
        "statMods": {
          "movementAds": 0.97,
          "xSway": 0.95,
          "ySway": 0.96
        }
      }
    ],
    "stock": [
      {
        "name": "No Stock",
        "type": [
          "stock"
        ],
        "id": 1650,
        "statMods": {
          "ads": -21,
          "movement": 1.017,
          "movementAds": 1.11,
          "vertRecoil": 1.38,
          "hipfireArea": 0.83,
          "xSway": 1.8,
          "ySway": 1.2
        }
      },
      {
        "name": "FSS Close Quarters Stock",
        "type": [
          "stock"
        ],
        "id": 1651,
        "statMods": {
          "ads": -13,
          "xSway": 1.14,
          "ySway": 1.16
        }
      },
      {
        "name": "Standard-Issue Wood Stock",
        "type": [
          "stock"
        ],
        "id": 1660,
        "statMods": {
          "movementAds": 0.89,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "FORGE TAC Ultralight",
        "type": [
          "stock"
        ],
        "id": 1661,
        "statMods": {
          "movementAds": 1.09,
          "xSway": 1.14,
          "ySway": 1.16
        }
      }
    ],
    "laser": [
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 1652,
        "statMods": {
          "ads": -21,
          "xSway": 0.55,
          "ySway": 0.6
        }
      },
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 1656,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 1657,
        "statMods": {
          "tstf": -29,
          "stf": -9,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 1653,
        "statMods": {
          "reload": -710
        }
      }
    ]
  },
  "AS VAL": {
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2352,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2353,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 2354,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2355,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 2356,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2357,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Dragunov Scope (5.5x)",
        "type": [
          "optic"
        ],
        "id": 2358,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2359,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2360,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 2361,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2362,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2363,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 2364,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 2365,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2366,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 2367,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 2368,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 2369,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2370,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 2371,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6x)",
        "type": [
          "optic"
        ],
        "id": 2372,
        "statMods": {
          "ads": 88
        }
      }
    ],
    "barrel": [
      {
        "name": "VLK 105mm Stova",
        "type": [
          "barrel"
        ],
        "id": 2373,
        "statMods": {
          "range": 0.9,
          "ads": -21,
          "bulletVelocity": 0.84
        }
      },
      {
        "name": "Stovl SOF",
        "type": [
          "barrel"
        ],
        "id": 2374,
        "statMods": {
          "range": 0.8,
          "ads": -38,
          "movement": 1.021,
          "bulletVelocity": 0.675,
          "vertRecoil": 1.28
        }
      },
      {
        "name": "VLK 200mm Osa",
        "type": [
          "barrel"
        ],
        "id": 2375,
        "statMods": {
          "range": 1.26,
          "ads": 21,
          "bulletVelocity": 1.29
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2376,
        "statMods": {
          "ads": 13,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Bipod (Crouched)",
        "type": [
          "underbarrel"
        ],
        "id": 2377,
        "statMods": {
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Bipod (Prone)",
        "type": [
          "underbarrel"
        ],
        "id": 2378,
        "statMods": {
          "vertRecoil": 0.65
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2379,
        "statMods": {
          "ads": 17,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2380,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2381,
        "statMods": {
          "ads": 25,
          "movementAds": 0.91,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Cronen Shark II",
        "type": [
          "underbarrel"
        ],
        "id": 2382,
        "statMods": {
          "movement": 0.989
        }
      }
    ],
    "ammunition": [
      {
        "name": "30 Round Mags",
        "type": [
          "ammunition"
        ],
        "id": 2383,
        "statMods": {
          "ads": 4,
          "movement": 0.983,
          "magSize": 10
        }
      }
    ],
    "rear grip": [
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2384,
        "statMods": {}
      },
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2385,
        "statMods": {
          "tstf": -71,
          "stf": -34
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2386,
        "statMods": {
          "vertRecoil": 0.94
        }
      }
    ],
    "stock": [
      {
        "name": "VLK Vintazh",
        "type": [
          "stock"
        ],
        "id": 2387,
        "statMods": {
          "movementAds": 0.9
        }
      },
      {
        "name": "FSS Intl. Gen 4 GRU",
        "type": [
          "stock"
        ],
        "id": 2388,
        "statMods": {
          "ads": -25
        }
      },
      {
        "name": "Stovl 6P30 Skelet",
        "type": [
          "stock"
        ],
        "id": 2389,
        "statMods": {
          "ads": -17,
          "movementAds": 1.17
        }
      },
      {
        "name": "VLK Strelok",
        "type": [
          "stock"
        ],
        "id": 2390,
        "statMods": {
          "ads": 8,
          "movementAds": 0.84,
          "vertRecoil": 0.8
        }
      }
    ],
    "laser": [
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 2391,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 2392,
        "statMods": {
          "ads": -33
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 2393,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 2394,
        "statMods": {
          "reload": -780
        }
      }
    ]
  },
  "AS VAL (SPP 10-R Mags)": {
    "optic": [
      {
        "name": "Operator Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2395,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "APX5 Holographic Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2396,
        "statMods": {
          "ads": 9
        }
      },
      {
        "name": "Scout Combat Optic (3.25x)",
        "type": [
          "optic"
        ],
        "id": 2397,
        "statMods": {
          "ads": 21
        }
      },
      {
        "name": "Cronen LP945 Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2398,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "4.0x Flip Hybrid (4x)",
        "type": [
          "optic"
        ],
        "id": 2399,
        "statMods": {
          "ads": 50
        }
      },
      {
        "name": "Viper Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2400,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Dragunov Scope (5.5x)",
        "type": [
          "optic"
        ],
        "id": 2401,
        "statMods": {
          "ads": 79
        }
      },
      {
        "name": "G.I. Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2402,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Corp Combat Holo Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2403,
        "statMods": {
          "ads": 17
        }
      },
      {
        "name": "VLK 3.0x Optic (3x)",
        "type": [
          "optic"
        ],
        "id": 2404,
        "statMods": {
          "ads": 25,
          "vertRecoil": 0.9,
          "horizRecoil": 0.9
        }
      },
      {
        "name": "Solozero Optics Mini Reflex (1x)",
        "type": [
          "optic"
        ],
        "id": 2405,
        "statMods": {
          "ads": 4
        }
      },
      {
        "name": "Aim-Op Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2406,
        "statMods": {
          "ads": 8
        }
      },
      {
        "name": "Integral Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 2407,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "Solozero NVG Enhanced (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 2408,
        "statMods": {
          "ads": 58,
          "vertRecoil": 0.85
        }
      },
      {
        "name": "Monocle Reflex Sight (1x)",
        "type": [
          "optic"
        ],
        "id": 2409,
        "statMods": {
          "ads": 12
        }
      },
      {
        "name": "Cronen C480 Pro Optic (3.5x)",
        "type": [
          "optic"
        ],
        "id": 2410,
        "statMods": {
          "ads": 33
        }
      },
      {
        "name": "Merc Thermal Optic (3.25x Thermal)",
        "type": [
          "optic"
        ],
        "id": 2411,
        "statMods": {
          "ads": 54
        }
      },
      {
        "name": "Canted Hybrid (3.25x + 1x)",
        "type": [
          "optic"
        ],
        "id": 2412,
        "statMods": {
          "ads": 42
        }
      },
      {
        "name": "PBX Holo 7 Sight (1.5x)",
        "type": [
          "optic"
        ],
        "id": 2413,
        "statMods": {
          "ads": 13
        }
      },
      {
        "name": "Thermal Hybrid (3.25x Thermal + 1x)",
        "type": [
          "optic"
        ],
        "id": 2414,
        "statMods": {
          "ads": 67
        }
      },
      {
        "name": "Variable Zoom Scope (3x + 6x)",
        "type": [
          "optic"
        ],
        "id": 2415,
        "statMods": {
          "ads": 88
        }
      }
    ],
    "barrel": [
      {
        "name": "VLK 105mm Stova",
        "type": [
          "barrel"
        ],
        "id": 2416,
        "statMods": {
          "range": 0.9,
          "ads": -21,
          "bulletVelocity": 0.84
        }
      },
      {
        "name": "Stovl SOF",
        "type": [
          "barrel"
        ],
        "id": 2417,
        "statMods": {
          "range": 0.8,
          "ads": -38,
          "movement": 1.021,
          "bulletVelocity": 0.675,
          "vertRecoil": 1.28
        }
      },
      {
        "name": "VLK 200mm Osa",
        "type": [
          "barrel"
        ],
        "id": 2418,
        "statMods": {
          "range": 1.26,
          "ads": 21,
          "bulletVelocity": 1.29
        }
      }
    ],
    "underbarrel": [
      {
        "name": "Merc Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2419,
        "statMods": {
          "ads": 13,
          "movement": 0.99,
          "movementAds": 0.92,
          "vertRecoil": 0.83,
          "hipfireArea": 0.81
        }
      },
      {
        "name": "Bipod (Crouched)",
        "type": [
          "underbarrel"
        ],
        "id": 2420,
        "statMods": {
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Bipod (Prone)",
        "type": [
          "underbarrel"
        ],
        "id": 2421,
        "statMods": {
          "vertRecoil": 0.65
        }
      },
      {
        "name": "Ranger Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2422,
        "statMods": {
          "ads": 17,
          "movement": 0.987,
          "movementAds": 0.92,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Commando Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2423,
        "statMods": {
          "movement": 0.98,
          "movementAds": 0.86,
          "vertRecoil": 0.94,
          "horizRecoil": 0.85
        }
      },
      {
        "name": "Operator Foregrip",
        "type": [
          "underbarrel"
        ],
        "id": 2424,
        "statMods": {
          "ads": 25,
          "movementAds": 0.91,
          "vertRecoil": 0.83
        }
      },
      {
        "name": "Cronen Shark II",
        "type": [
          "underbarrel"
        ],
        "id": 2425,
        "statMods": {
          "movement": 0.989
        }
      }
    ],
    "rear grip": [
      {
        "name": "Granulated Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2426,
        "statMods": {}
      },
      {
        "name": "Stippled Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2427,
        "statMods": {
          "tstf": -71,
          "stf": -34
        }
      },
      {
        "name": "Rubberized Grip Tape",
        "type": [
          "rear grip"
        ],
        "id": 2428,
        "statMods": {
          "vertRecoil": 0.94
        }
      }
    ],
    "stock": [
      {
        "name": "VLK Vintazh",
        "type": [
          "stock"
        ],
        "id": 2429,
        "statMods": {
          "movementAds": 0.9
        }
      },
      {
        "name": "FSS Intl. Gen 4 GRU",
        "type": [
          "stock"
        ],
        "id": 2430,
        "statMods": {
          "ads": -25
        }
      },
      {
        "name": "Stovl 6P30 Skelet",
        "type": [
          "stock"
        ],
        "id": 2431,
        "statMods": {
          "ads": -17,
          "movementAds": 1.17
        }
      },
      {
        "name": "VLK Strelok",
        "type": [
          "stock"
        ],
        "id": 2432,
        "statMods": {
          "ads": 8,
          "movementAds": 0.84,
          "vertRecoil": 0.8
        }
      }
    ],
    "laser": [
      {
        "name": "1mW Laser",
        "type": [
          "laser"
        ],
        "id": 2433,
        "statMods": {
          "hipfireArea": 0.64
        }
      },
      {
        "name": "Tac Laser",
        "type": [
          "laser"
        ],
        "id": 2434,
        "statMods": {
          "ads": -33
        }
      },
      {
        "name": "5mW Laser",
        "type": [
          "laser"
        ],
        "id": 2435,
        "statMods": {
          "tstf": -79,
          "stf": -46,
          "hipfireArea": 0.444
        }
      }
    ],
    "perk": [
      {
        "name": "Sleight of Hand",
        "type": [
          "perk"
        ],
        "id": 2436,
        "statMods": {
          "reload": -780
        }
      }
    ]
  }
}