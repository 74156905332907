import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import GlobalContext from './GlobalContext';

function DeleteAllButton(props) {
  const { bpList, updateBpList } = useContext(GlobalContext);
  if (bpList.length === 0) return (<></>);
  return (<Button id="del-all" variant="outline-danger" block onClick={() => updateBpList(undefined, 'all')}>Delete All Blueprints</Button>);
}

export default DeleteAllButton;