import React, { useContext } from 'react';
import { Toast, Badge } from 'react-bootstrap';
import GlobalContext from './GlobalContext';
import { convertToOldBp } from './dataUtils';

const badgeVariant = {
  AR: "primary",
  LMG: "danger",
  SMG: "warning"
}

function GunListItem(props) {
  let { item, id, closeButton } = props;
  const itemCopy = convertToOldBp(JSON.parse(JSON.stringify(item)));
  const { updateBpList } = useContext(GlobalContext);
  if (!item) return (<></>);

  const gun = itemCopy.shift();
  const attachments = itemCopy;

  const renderAttachmentList = () => {
    return attachments
      .map((x, i) => <li key={i}>{x.name}</li>);
  };

  const onClose = (event) => {
    updateBpList(undefined, id);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Toast className="gun-card" onClose={onClose}>
      <Toast.Header onClick={(event) => { }} closeButton={closeButton}>
        <strong className="mr-auto">
          {gun.bpName + ' '}
          <Badge variant={badgeVariant[gun.type]}>{gun.type}</Badge>
        </strong>
        <small></small>
      </Toast.Header>
      <Toast.Body>
        <ul>
          {renderAttachmentList()}
        </ul>
      </Toast.Body>
    </Toast>
  )
}

export default GunListItem;