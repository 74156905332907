import React, { useContext } from 'react';
import GlobalContext from './GlobalContext';

// Available Charts
import TtkChart from './TtkChart';
import DpsChart from './DpsChart';
import StkChart from './StkChart';

function DamageChartArea() {
  let { chartType } = useContext(GlobalContext);
  chartType = chartType || 'ttk';

  if (chartType === 'dps') {
    return (
      <div className="horizontal-scrolling">
        <div className="chart-area">
          <DpsChart></DpsChart>
        </div>
      </div>
    )
  }
  else if (chartType === 'stk') {
    return (
      <div className="horizontal-scrolling">
        <div className="chart-area">
          <StkChart></StkChart>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="horizontal-scrolling">
        <div className="chart-area">
          <TtkChart></TtkChart>
        </div>
      </div>
    );
  }
}

export default DamageChartArea;