import React from 'react';

function ToDoList() {

  return (
    <div>TODO:
      <ul>
        {/* <li>traditional input method</li> */}
        {/* <li>URL for link sharing</li> */}
        <li>Error Handeling (Seperate boundries for each section)</li>
        <li>Add Pro Bp Bar</li>
        <li>Fix Ammo conversions</li>
        <li>move share link to top</li>
        <li>Edit/copy blueprint function. "click the current gun I want to change attachments for, click the attachment, and a list of other attachments for that slot shows up, and I pick a new one."</li>
        <li>Colorize tables</li>
        <li> - </li>
        <li>UI: once you select a gun, the attachment list doesn't auto pop up. you have to click on the text box again.</li>
        <li> - </li>
        <li>mobile: layout</li>
        <li>refactor</li>
        <li>Sorting the BP List</li>
        <li>hide button</li>
        <li>Sorting function</li>
      </ul>
    </div>
  );
}

export default ToDoList;